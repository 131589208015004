import history2 from "../../assets/history/mockup_history_img_2.png"
import history3 from "../../assets/history/mockup_history_img_3.png"
import history4 from "../../assets/history/mockup_history_img_4.png"
import history5 from "../../assets/history/mockup_history_img_5.png"
import history6 from "../../assets/history/mockup_history_img_6.png"
import history7 from "../../assets/history/mockup_history_img_7.png"
import historyFounder from "../../assets/history/history_founder.png"
import historyActivity from "../../assets/history/history_activity.png"
import { Col, Container, Image, Row } from "react-bootstrap"
import CustomHeading from "../reusable/CustomHeading"
import { useOutletContext } from "react-router-dom"
import langId from '../../utils/lang/id'
import { useEffect } from "react"
import Palette from "../utils/Palette"
export default function HistoryPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    const historyData = [
        {
            period: 1968,
            image: history2,
            description: 'Labschool bermula sebagai sekolah teladan yang didirikan tanggal 12 Februari 1968. Yaitu sebuah sekolah yang dimaksudkan sebagai sekolah laboratorium IKIP Jakarta. Sekolah ini digunakan untuk praktik mengajar, penelitian pendidikan, dan inovasi pendidikan.Periode 1972 Sekolah Teladan berganti nama menjadi “Comprehensive School” atau Sekolah Proyek TPK. Sekolah ini mengemban tugas sebagai pelaksana proyek Tempat Pembinaan Ketrampilan (TPK) dari Departemen Pendidikan dan Kebudayaan. Proyek TPK bertujuan untuk mencobakan ide-ide baru dalam bidang pendidikan.'
        },
        {
            period: 1974,
            image: history3,
            description: `Di tahun tersebut, sekolah telah memiliki nama dan fungsi yang baru sebagai sekolah pelaksana proyek PPSP (Proyek Perintis Sekolah Pembangunan). Proyek ini bertujuan untuk menguji cob aide-ide dalam pendidikan guna memberi masukan bagi pembaharuan pendidikan nasional. Sekolah PPSP berlangsung selama dua belas tahun. Tahun 1986 proyek ini berakhir.`
        },
        {
            period: 1986,
            image: history4,
            description: 'Setelah proyek PPSP berakhir, berganti pula nama sekolah-sekolah yang dibawah naungan IKIP Jakarta ini. SD, SMP, dan SMA eks PPSP IKIP Jakarta berganti nama menjadi SD Negeri Komplek IKIP Jakarta, SMP Negeri 236, dan SMA Negeri 81, sedangkan TK-nya masih tetap berstatus swasta dengan nama TK IKIP Jakarta.'
        },
        {
            period: 1992,
            image: history5,
            description: 'Pada tahun tersebut, SMPN 236 berpindah lokasi ke komplek PIK Cakung dan SMAN 81 berpindah lokasi ke Komplek Kodam Cipinang Melayu. Atas permintaan masyarakat, mulai tahun pelajaran 1992/1993 Yayasan Pembina IKIP Jakarta membuka SMP dan SMA IKIP Jakarta dengan SK Kanwil P dan K DKI tertanggal 15 Maret 1993 No. Kep. 854 P/I01.a1/1/93 dan No. Kep 853 A/I01.a1/1/93.'
        },
        {
            period: 1999,
            image: history6,
            description: 'Seiring perluasan mandate dan bergantinya nama IKIP Jakarta menjadi Universitas Negeri Jakarta, maka mulai tahun 1999, sekolah TK, SLTP, SMU IKIP Jakarta berganti nama menjadi TK, SMP, dan SMU Labschool.'
        },
        {
            period: 2001,
            image: history7,
            description: 'Berdasarkan Izin Kanwil Depdiknas DKI Jakarta No. 047/I01.G/PP/2001 dan No. 048/I01.G/PP/2001, didirikan SLTP dan SMU Labschool Kebayoran yang peresmiannya dilaksanakan pada 12 Juli 2001. Labschool Kebayoran ini merupakan upaya perluasan layanan pendidikan kepada masyarakat setelah suksesnya penyelenggaraan Labschool Jakarta.'
        },


    ]

    useEffect(() => {
        //about = aboutId
    }, [])

    const renderHistory = () => {
        return (
            <>
                <Container style={{ padding: isMobile ? '1rem 1rem' : '8rem 0rem' }}>
                    <Row className="mb-5">
                        <Col md={9}
                            style={
                            !isMobile ?
                            {
                                position: 'relative',
                                left: 30,
                                paddingRight:0
                            }
                        : {

                                }
                        }
                        >
                            <CustomHeading text={lang.HistoryPage.title} />
                        </Col>
                    </Row>
                    {

                    }
                    <Row className={`mb-${isMobile ? '3' : '5'}`}>
                        <Col lg={10} xl={10} md={9}
                            style={
                            !isMobile ?
                            {
                                position: 'relative',
                                left: 30
                            } :  {

                                }}
                        >
                            <p
                            style={{
                                textAlign:'justify',

                            }}
                            >{lang.HistoryPage.paragraphs[2]}</p>
                            {
                                !isMobile && <p>{lang.HistoryPage.paragraphs[3]}</p>
                            }

                        </Col>
                        <Col className="text-center">
                            <Image style={{ height: 200, objectFit: 'cover' }} src={historyFounder}></Image>
                        </Col>
                    </Row>
                    {
                        isMobile &&
                        <Row>
                            <Col md={12} xs={12} sm={12}>
                                <p
                                    style={{
                                        textAlign:'justify',
                                    }}
                                >{lang.HistoryPage.paragraphs[3]}</p>
                            </Col>

                        </Row>

                    }

                    <Row className="mb-5">
                        <Col
                            style={isMobile ? {marginBottom:30} : {}}
                            className="text-center" lg={5} xl={5} md={12}>
                            {
                                isMobile && <Image style={{ maxHeight: 300, width : 'inherit' }} src={historyActivity}></Image>
                            }

                            {
                                !isMobile && <Image style={{ maxHeight: 300 }} src={historyActivity}></Image>
                            }
                        </Col>
                        <Col sm={12} md={12} lg={7} xl={7}>

                            <p
                                style={{
                                    textAlign:'justify'
                                }}
                            >{lang.HistoryPage.paragraphs[2]}</p>
                            <p
                                style={{
                                    textAlign:'justify'
                                }}
                            >{lang.HistoryPage.paragraphs[3]}</p>
                        </Col>

                    </Row>


                    {/* <Row className="mb-5">
                        <div className="apland-timeline-area">
                            {
                                historyData?.map(v => (
                                    <>
                                        <div className="single-timeline-area">
                                            <div
                                                className="timeline-date wow fadeInLeft"
                                                data-wow-delay="0.1s"
                                                style={{
                                                    visibility: "visible",
                                                    animationDelay: "0.1s",
                                                    animationName: "fadeInLeft"
                                                }}
                                            >
                                                <p style={{ fontSize: '1em', fontWeight: 800 }}>{v?.period}</p>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <Row className="mb-3">
                                                        <Col sm={6} xs={6} md={3}>
                                                            <Image alt={`img-${v?.period}`} src={v?.image} fluid />
                                                        </Col>
                                                        <Col md={9} style={{ fontSize: isMobile ? '0.8em' : '0.9em', lineHeight: 2, marginTop: 'auto', marginBottom: 'auto', textAlign: 'left' }}>
                                                            {v?.description}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                ))
                            }

                        </div>

                    </Row> */}


                </Container>
            </>
        )
    }
    return (
        <>
            {renderHistory()}
        </>
    )
}