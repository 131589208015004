import karyawisataImage from "../../assets/activity/karyawisata-enhance.jpg"
import basketImage from "../../assets/activity/basket.jpg"
import kegiatanImage from "../../assets/activity/kegiatan.jpg"
import osisImage from "../../assets/activity/osis.jpg"
import paskibraImage from "../../assets/activity/paskib.jpg"
import labImage from "../../assets/activity/laboratorium.jpg"
import karateImage from "../../assets/activity/karate.jpg"
import tariImage from "../../assets/activity/tari.jpg"
export default [
    {
        name: 'Basket',
        image: basketImage
    },
    {
        name: 'Karyawisata',
        image: karyawisataImage
    },
    {
        name: 'LDKS',
        image: kegiatanImage
    },
    {
        name: 'Laboratorium',
        image: labImage
    },
    {
        name: 'Osis',
        image: osisImage
    },
    {
        name: 'Paskibra',
        image: paskibraImage
    },
    {
        name: 'Sholat Berjamaah',
        image: labImage
    },
    {
        name: 'Tadarus Bersama',
        image: labImage
    },
    {
        name: 'Voli',
        image: labImage
    },
    {
        name: 'Badminton',
        image: labImage
    },
    {
        name: 'Tari',
        image: tariImage
    },
    {
        name: 'Pramuka',
        image: labImage
    },
    {
        name: 'Futsal',
        image: labImage
    },
    {
        name: 'Karate',
        image: karateImage
    }
    
]
