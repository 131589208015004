import React, { useEffect } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery'
// import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useOutletContext } from 'react-router-dom';
import galleryImage from "../../assets/gallery.jpg"
const GalleryThumb = ({ items }) => {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    useEffect(() => {
        console.log('context', items)
    }, [])
    return (
        <Gallery>
            {
                items?.map((value) => {
                    return (
                        <>
                            <Item
                                original={value}
                                thumbnail={value}
                                
                            >
                                {({ ref, open }) => (
                                    <img style={{ width: isMobile ? 150 : 250, height: isMobile ? 130 : 230, marginBottom: 30, objectFit:'cover' }} ref={ref} onClick={open} src={value} />
                                )}
                            </Item>
                        </>
                    )
                })
            }

        </Gallery>
    );
};

export default GalleryThumb;
