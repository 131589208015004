export default {
    mission: [
        'Meningkatkan keimanan dan ketaqwaan kepada Tuhan Yang Maha Esa',
        'Menjunjung tinggi nilai kejujuran dan selalu rendah hati',
        `Mewujudkan kepedulian terhadap lingkungan`,
        `Membentuk siswa yang bertanggung jawab, gigih dan kreatif`,
        `Menciptakan lulusan yang intelektual dan humanis`
    ],
    vision: [
        {
            name: 'Taat beragama, santun berperilaku dan unggul dalam prestasi',
            icon: 'fa-solid:praying-hands',
        },
    ],
    about: <>
        SMA Sunda Kelapa, yang berdiri sejak 8 Agustus 1963, merupakan salah satu lembaga pendidikan yang menjadi bagian integral dari Yayasan Pendidikan Sunda Kelapa. Yayasan ini didirikan dan dipimpin dengan penuh dedikasi oleh Ny. Hj. S. Moerdono, seorang tokoh pendidikan terkemuka pada zamannya. Proses pembentukan lembaga pendidikan ini juga mendapat kontribusi berharga dari Ibu Pia Alisyahbana dan Ibu Popy Awaludin, yang berperan sebagai pembina yang berdedikasi.


        SMA Sunda Kelapa diresmikan oleh Ibu Fatmawati Soekarno, seorang Ibu Negara pertama Republik Indonesia. Dengan semangat luar biasa dalam meningkatkan kualitas pendidikan nasional, sekolah ini terus berupaya memberikan kontribusi yang signifikan. Untuk mencapai tujuan tersebut, SMA Sunda Kelapa telah mendapatkan dukungan kuat dari sejumlah individu berpengaruh dalam masyarakat.
        <br />
        <br />
        Tidak hanya bergantung pada dukungan eksternal, SMA Sunda Kelapa juga dikenal memiliki staf pengajar yang memiliki dedikasi tinggi, pengalaman yang luas, dan keahlian yang mendalam di bidang pendidikan. Mereka telah memainkan peran penting dalam membentuk dan mengembangkan peserta didiknya menjadi individu yang berkualitas dan berpotensi.

        Sejak berdiri dan menjadi salah satu sekolah swasta pertama serta saat ini dapat dikatakan sebagai salah satu sekolah swasta tertua, SMA Sunda Kelapa telah menjadi salah satu lembaga pendidikan yang berkomitmen untuk menjalankan misi penting dalam memajukan pendidikan di Indonesia. Melalui dedikasi dan komitmen yang tinggi, sekolah ini terus menjadi salah satu pilar pendidikan yang berpengaruh dalam pembentukan generasi muda yang berkualitas dan siap menghadapi masa depan.
    </>,
    coreValues:
        [
            {
                title: 'Beriman',
                detail: <>
                    <ul style={{ listStyle: 'auto' }}>
                        <li>Meningkatkan keimanan dan ketaqwaan terhadap Tuhan YME. </li>
                        <li>Melaksanakan ibadah sesuai keyakinan masing-masing.</li>
                        <li>Memperingati hari besar keagamaan.</li>


                    </ul>
                </>
            },
            {
                title: 'Percaya Diri',
                detail: <>
                    <ul style={{ listStyle: 'auto' }}>
                        <li>Berprestasi dalam bidang akademik maupun non-akademik.  </li>
                        <li>Meraih prestasi dalam kompetisi baik di sekolah ataupun diluar sekolah di bidang sains, kreasi, seni, dan olahraga.</li>
                        <li>Berani mengemukakan pendapat. </li>
                        <li>Bertanggung jawab sesuai tugasnya. </li>
                    </ul>
                </>
            },
            {
                title: 'Rendah Hati',
                detail: <>
                    <ul style={{ listStyle: 'auto' }}>
                        <li>Menjunjung tinggi nilai-nilai kejujuran.  </li>
                        <li>Bergotong royong dan bekerja sama dalam kebaikan</li>
                        <li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
                        <li>Tidak sombong dan menghargai pendapat orang lain.</li>
                    </ul>
                </>
            },
            {
                title: 'Santun',
                detail: <>
                    <ul style={{ listStyle: 'auto' }}>
                        <li>Menanamkan sikap sopan dan santun di dalam pergaulan.
                        </li>
                        <li>Bertutur kata sopan kepada siapapun. </li>
                        <li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
                        <li>Selalu disiplin dalam melaksanakan tata-tertib/peraturan.</li>
                    </ul>
                </>
            },
            {
                title: 'Kreativitas',
                detail: <>
                    <ul style={{ listStyle: 'auto' }}>
                        <li>Mampu menciptakan hasil karya yang baru dalam bidang sains.
                        </li>
                        <li>Mampu berkreasi dalam bidang seni dan olahraga.</li>
                        <li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
                        <li>Selalu disiplin dalam melaksanakan tata-tertib/peraturan.</li>
                    </ul>
                </>
            },
            {
                title: 'Inovatif',
                detail: <>
                    <ul style={{ listStyle: 'auto' }}>
                        <li>Mampu menciptakan hasil karya ilmiah.
                        </li>
                        <li>Mampu mengembangkan gagasan/ide yang baru</li>
                        <li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
                        <li>Tangguh dalam menyelesaikan masalah.</li>
                    </ul>
                </>
            },
        ]
}