import { Col, Container, Image, Row } from "react-bootstrap"
import Palette from "../utils/Palette"
import { Icon } from "@iconify/react"
import CustomHeading from "../reusable/CustomHeading"
import CustomIcon from "../utils/CustomIcon"
import { useEffect, useRef } from "react"
import { Link, useOutletContext } from "react-router-dom"
import noImage from "../../assets/noimage.png"
import aboutFull from "../../assets/about/banner_1_tall.jpg"
import coreValueImage from "../../assets/posterCoreValues.jpg"
import About from "../models/About"
import Extrakulikuler from "../models/Extrakulikuler"
import { LazyLoadImage } from "react-lazy-load-image-component"
import principalImage from "../../assets/teacher/Ir. Aminah.jpg"
import langId from "../../utils/lang/id"
export default function AboutPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    const searchParams = new URLSearchParams(window.location.search).get("ref")
    const ourMissionData = [
        "Meningkatkan ketaqwaan terhadap Tuhan Yang Maha Esa",
        "Meningkatkan mutu Kegiatan Belajar Mengajar",
        "Meningkatkan kedisiplinan dan keterampilan",
        "Meningkatkan rasa sosial yang tinggi",
        "Berusaha meraih prestasi",
        "Membina hubungan kerjasama dengan masyarakat",
        "Mewujudkan lingkungan yang ASRI dan penuh kekeluargaan."
    ]
    let visionRef = useRef(null)
    let coreValueRef = useRef(null)
    let generalInfoRef = useRef(null)
    let about = langId

    useEffect(() => {
        setTimeout(() => {
            if (searchParams === "vision") {
                visionRef.current.scrollIntoView({
                    behavior: "smooth",
                    alignToTop: false,
                    inline: "end",
                    block: "end"
                })
                // window.scrollTo({ behavior: 'smooth', top: visionRef?.current?.offsetTop })
            } else if (searchParams === "core-value") {
                coreValueRef.current.scrollIntoView({ behavior: "smooth" })
                // window.scrollTo({ behavior: 'smooth', top: coreValueRef?.current?.offsetTop })
            } else {
                generalInfoRef.current.scrollIntoView({ behavior: "smooth" })
                // window.scrollTo({ behavior: 'smooth', top: generalInfoRef?.current?.offsetTop })
            }
        }, 100)
    }, [searchParams])

    return (
        <>
            <Container
                ref={generalInfoRef}
                style={{
                    paddingTop: isMobile ? "1rem" : "8rem",
                    paddingBottom: isMobile ? "1rem" : "0"
                }}
            >
                <Row>
                    <CustomHeading text={lang.AboutPage.title} />
                </Row>


                <Row className="justify-content-center mt-4">
                    <Col md={12}>
                        <div style={{ fontSize: "1em", lineHeight: 2 }}>
                            {lang.HomePage.about.description}
                        </div>

                    </Col>
                </Row>
                <Row className="mt-5 mb-3" style={{ fontSize: '1.2em',  justifyContent: 'center', width: isMobile ? '100%' : '80%', margin: '0 auto' }}>
                    {
                        isMobile ?
                            <>
                                <Col md={4} className={'text-center mb-2'}>
                                    <div>
                                        {lang.AboutPage.accreditations.num_title}
                                    </div>
                                    <div>
                                        {lang.AboutPage.accreditations.num_value}
                                    </div>
                                </Col>
                                <Col md={4} className={'text-center mb-2'}>
                                    <div>
                                        {lang.AboutPage.accreditations.established_title}
                                    </div>
                                    <div>
                                        {lang.AboutPage.accreditations.established_value}
                                    </div>
                                </Col>
                                <Col md={4} className={'text-center mb-2'}>
                                    <div>
                                        {lang.AboutPage.accreditations.established_title}
                                    </div>
                                    <div>
                                        {lang.AboutPage.accreditations.established_value}
                                    </div>
                                </Col>
                            </>
                            :
                            <>
                                <Col>{lang.AboutPage.accreditations.num_title}{lang.AboutPage.accreditations.num_value}</Col>
                                <Col>{lang.AboutPage.accreditations.established_title}{lang.AboutPage.accreditations.established_value}</Col>
                               <Col>{lang.AboutPage.accreditations.established_by_title}{lang.AboutPage.accreditations.established_by_value}</Col>
                           </>

                    }

                </Row>
                <Row className={"mt-5"}>
                    <Col md={12} className="text-center">
                        <img
                            style={{
                                maxHeight: "75vh",
                                width: "80%",
                                objectFit: "cover"
                            }}
                            src={aboutFull}
                        />
                    </Col>
                </Row>
                <div></div>
            </Container>

            <svg
                style={{ marginTop: isMobile ? 20 : -50 }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 60"
                fill="none"
            >
                <path
                    width="100%"
                    height="100%"
                    d="M0 30.9096C619 -9.8169 831.5 -10.7866 1440 30.9096V60H0V30.9096Z"
                    fill="#F7F7F8"
                />
            </svg>

            <div className="w-100" style={{ background: '#f7f7f8', marginTop: -10 }}>
                <Container className={`pt-5 ${isMobile ? 'pb-2' : 'pb-5'}`}>
                    <Row>
                        <Col className={"d-flex flex-column"} md={12}>
                            <CustomHeading text={lang.HomePage.greetings.title} />
                        </Col>
                    </Row>
                    <Row className={"mt-5 justify-content-around"}>
                        <Col
                            className={isMobile ? "text-center" : "text-center"}
                            lg={3}
                            xl={3}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <LazyLoadImage
                                effect="blur"
                                style={{
                                    textAlign: "center",
                                    width: 250,
                                    height: 250,
                                    borderRadius: "100%",
                                    objectFit: "cover"
                                }}
                                alt={"about-section-image"}
                                src={principalImage}
                            />

                            <h4
                                style={{
                                    textAlign: "center",
                                    fontWeight: 800,
                                    fontSize: "1.4em",
                                    marginTop: 20
                                }}
                            >
                                Ir. Aminah
                            </h4>
                            <p style={{ textAlign: "center" }}>
                                {lang.HomePage.greetings.position}
                            </p>
                        </Col>

                        <Col lg={8} xl={8} md={12} sm={12} xs={12} className={"d-flex flex-column justify-content-center"}>
                            <div style={{ fontSize: "1em", lineHeight: 2 }}>
                                {lang.HomePage.greetings.paragraph}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Container
                ref={visionRef}
                fluid
                style={{ background: "#F7F7F8", position: "relative", top: -20, padding: 0 }}
            >
                <Container>
                    <Row>
                        <Col
                            className={isMobile ? "mt-5 mb-2" : "mb-2 mt-5"}
                            md={12}
                            xs={12}
                            sm={12}
                        >
                            <CustomHeading align={'center'} fontSize={'2.3em'} text={lang.AboutPage.vision.title} />
                            <Row className="mt-1">
                                <Col md={12} xs={12} sm={12}>
                                    {lang.AboutPage.vision.paragraphs.map((v) => {
                                        return (
                                            <>
                                                <Col className="mb-4" md={12}>
                                                    <CustomIcon
                                                        rowClass={`w-100 ${isMobile ? '' : 'justify-content-center'}`}
                                                        clientTextSize={isMobile ? 18 : 18}
                                                        clientText={v.name}
                                                        clientIcon={v.icon}
                                                    />
                                                </Col>
                                            </>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            className={isMobile ? "mt-5 mb-5" : "mb-5"}
                            md={12}
                            xs={12}
                            sm={12}
                        >
                            <CustomHeading fontSize={'2.3em'} align={'center'} text={lang.AboutPage.mission.title} />

                            <div className="mt-1" style={{ fontSize: "1em", justifyContent: 'center', display: 'grid' }}>
                                {lang.AboutPage.mission.paragraphs.map((v) => {
                                    return (
                                        <>
                                            <div className={`d-flex w-100`}>
                                                <div
                                                    style={{
                                                        width: isMobile ? 'auto' :  '2.75rem',
                                                        height: '2.7rem',
                                                        // padding: "0.5rem",
                                                        display: "flex",
                                                        marginLeft: isMobile ? '0' : '5rem',
                                                        marginRight: isMobile ? 20 : 0,

                                                        justifyContent: isMobile ? "flex-start"  : "center",
                                                        alignItems: "center",
                                                        color: "#0DAE56",
                                                        borderRadius: "100%"
                                                    }}
                                                >
                                                    <Icon style={{ color: Palette.THEME_GREEN }} icon={"material-symbols:circle"} />
                                                </div>
                                                <div style={{  marginLeft: isMobile ? 0 : 10, fontSize: 18, fontWeight: 600 }} className="my-auto">
                                                    {v}
                                                </div>
                                            </div>
                                            {/* <Row>
                                                <Col
                                                    className="w-auto mb-2"
                                                    style={{ paddingRight: 0 }}
                                                    md={1}
                                                >
                                                    <Icon
                                                        icon={"material-symbols:circle"}
                                                        style={{ color: Palette.THEME_GREEN }}
                                                    />
                                                </Col>
                                                <Col
                                                    style={
                                                        isMobile
                                                            ? {
                                                                fontSize: "0.9em",
                                                                fontWeight: 600,
                                                                lineHeight: 2
                                                            }
                                                            : { fontWeight: 600 }
                                                    }
                                                >
                                                    {v}
                                                </Col>
                                            </Row> */}
                                        </>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <svg
                    ref={coreValueRef}
                    style={isMobile ? { marginTop: 10 } : {}}
                    width="100%"
                    height="100%"
                    viewBox="0 0 1440 60"
                    fill={Palette.THEME_BG_BEIGE}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        width="100%"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 32.8545L39.6 27.1991C80.4 21.5438 159.6 10.2332 240 4.57784C320.4 -1.07748 399.6 -1.07748 480 2.31571C560.4 5.70891 639.6 12.4953 720 11.3642C800.4 10.2332 879.6 1.18465 960 6.83997C1040.4 12.4953 1119.6 32.8545 1200 36.2477C1280.4 39.6408 1359.6 26.0681 1400.4 19.2817L1440 12.4953V60H1400.4C1359.6 60 1280.4 60 1200 60C1119.6 60 1040.4 60 960 60C879.6 60 800.4 60 720 60C639.6 60 560.4 60 480 60C399.6 60 320.4 60 240 60C159.6 60 80.4 60 39.6 60H0V32.8545Z"
                        fill={Palette.THEME_BG_BEIGE}
                    />
                </svg>
            </Container>

            <Container>
                <Row className="mb-5 mt-3">
                    <CustomHeading text={lang.AboutPage.coreValues.title} />
                </Row>
                <Row className={"mt-5 mb-5"}>
                    <Col md={12} className="text-center">
                        <img
                            style={{
                                width: "80%",
                                objectFit: "cover"
                            }}
                            src={coreValueImage}
                        />
                    </Col>
                </Row>
                {lang.AboutPage.coreValues.paragraphs.map((value) => {
                    return (
                        <>
                            <Row className="mb-3">
                                <h5 style={{ color: Palette.THEME_GREEN, fontWeight: 600 }}>
                                    {value?.title}
                                </h5>
                                <div
                                    style={
                                        isMobile
                                            ? { fontSize: "0.9em", lineHeight: 2 }
                                            : { color: Palette.THEME_TEXT_DARK }
                                    }
                                >
                                    {value.detail}
                                </div>
                            </Row>
                        </>
                    )
                })}
            </Container>
            {/* <Container className="mb-5">
                <Row>
                    <CustomHeading text={about.AboutPage.extracurricular.title} />
                    <p>{about.AboutPage.extracurricular.description}</p>
                </Row>
                <Row className="mt-4">
                    {Extrakulikuler.map((v, index) => {
                        return (
                            <>
                                <Col className="mb-4" md={3} lg={3} xl={3} sm={6} xs={6}>
                                    <Image
                                        style={{
                                            minHeight: isMobile ? 150 : 350,
                                            objectFit: "cover"
                                        }}
                                        className="img-responsive img-fluid"
                                        src={v.image ? v.image : noImage}
                                    ></Image>
                                    <div
                                        style={{
                                            position: "relative",
                                            bottom: "15%",
                                            background: "#000",
                                            opacity: "0.6"
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: "#FFF",
                                                fontWeight: 800,
                                                textAlign: "center",
                                                padding: 20,
                                                marginTop: "auto",
                                                marginBottom: "auto"
                                            }}
                                        >
                                            {about.AboutPage.extracurricular.paragraphs[index].name}
                                        </p>
                                    </div>
                                </Col>
                            </>
                        )
                    })}
                </Row>
            </Container> */}
        </>
    )
}
