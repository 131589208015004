import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row
} from "react-bootstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link, useOutletContext } from "react-router-dom"
import paperBackground from '../../assets/paperBackgroundBlack.png'
import CustomHeading from "../reusable/CustomHeading"
import noImage from "../../assets/noimage.png"
import Palette from "../utils/Palette"
import Osis from "../models/Osis"
import { Icon } from "@iconify/react"

export default function OsisPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    return (
        <>
            <div style={{
                background: `url(${paperBackground}), lightgray 50% / cover no-repeat`
            }}>
                <Container
                    style={{
                        paddingTop: isMobile ? "1rem" : "8rem",
                        paddingBottom: isMobile ? "1rem" : "1rem",

                    }}
                >
                    <Row className="mb-5">
                        <CustomHeading text={lang.OsisPage.title} color={Palette.THEME_WHITE} />
                    </Row>
                    <Row className={"mt-5 mb-5"}>
                        <Col md={10} xs={12} className={"d-flex flex-column"}>
                            <div
                                style={
                                    isMobile
                                        ? { fontSize: "0.9em", lineHeight: 2, color: Palette.THEME_WHITE }
                                        : { fontSize: "1em", color: Palette.THEME_WHITE }
                                }
                            >
                                {lang.OsisPage.description}
                            </div>
                        </Col>
                        <Col style={isMobile ? { textAlign: 'center', marginTop: 10 } : {}} md={2} xs={12}>
                            <img
                                style={{
                                    height: isMobile ? 180 : 200,
                                    width: isMobile ? 150 : 180
                                }}
                                src={noImage}
                            />
                        </Col>
                    </Row>
                </Container>
                <Container
                    style={{
                        paddingBottom: isMobile ? "1rem" : "1rem",
                    }}
                >
                    <Row className="mb-5">
                        <CustomHeading fontSize={'1.2em'} text={'3 Periode Terakhir'} color={Palette.THEME_WHITE} />
                    </Row>
                    {
                        lang.OsisPage.list.map((v) => {
                            return (
                                <>
                                    <Row className="mb-5">
                                        <Col xs={12} md={4}>
                                            <LazyLoadImage
                                                effect="blur"
                                                style={{
                                                    textAlign: "center",
                                                    width: '100%',
                                                    height: 250,
                                                    objectFit: "cover"
                                                }}
                                                src={v.periodImage}
                                            />
                                        </Col>
                                        <Col className={`my-auto ${isMobile ? 'mt-2' : ''}`}>
                                            <CustomHeading fontSize={'1.1em'} text={v.periodTitle} color={Palette.THEME_WHITE} />
                                            <p style={{
                                                color: Palette.THEME_LIGHT_GRAY
                                            }}>{v.periodDescription}</p>
                                        </Col>
                                    </Row>
                                </>
                            )
                        })
                    }
                    <Row className="mb-4 mt-4">
                        <CustomHeading fontSize={'1.2em'} text={'Media Sosial'} color={Palette.THEME_WHITE} />
                    </Row>
                    <Link target="_blank" style={{
                        textDecoration: 'none'
                    }} to={'https://www.instagram.com/osis.smasundakelapa?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='}>
                        <Row className="mb-5" style={{
                            color: Palette.THEME_WHITE
                        }}>

                            <div style={{ width: 'auto', paddingRight: 0 }}>
                                <Icon style={{ width: 30, height: 30 }} icon="ant-design:instagram-filled"></Icon>
                            </div>
                            <Col>osis.smasundakelapa</Col>

                        </Row>
                    </Link>
                </Container>
            </div>

        </>
    )
}