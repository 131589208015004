import { useOutletContext } from "react-router-dom";
import Palette from "../utils/Palette";

export default function CustomHeading({ text, align, fontSize, color }) {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()

    return <div className={"d-flex flex-column"} style={{ overflowX: 'clip', textOverflow: 'clip', textAlign: align ? align : 'left' }}>
        <b style={{ color: color ? color : Palette.THEME_TEXT_DARK, fontWeight: 700, fontSize: fontSize ? fontSize : "2em" }}>{text}</b>
        {/* <b className="custom-heading-shadow" style={{color : Palette.SHADOW, fontSize : "2.5em", marginTop : '-1.22em', whiteSpace: 'nowrap', overflowX: 'clip'}}>{text}</b> */}
        {/* <div style={{height : 2, width : 40, background : Palette.THEME_BLUE,}}/> */}
    </div>
} 