import { Col, Container, Row } from "react-bootstrap";
import CustomHeading from "../reusable/CustomHeading";
import principalImage from '../../assets/principal.png'
import { useOutletContext } from "react-router-dom";
import Management from "../models/Management"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import _ from 'lodash'
import { useEffect, useState } from "react";
import langId from "../../utils/lang/id"
export default function TeacherPage() {
    const [foundationList, setFoundationList] = useState([])
    useEffect(() => {
        let resFoundation = _.mapValues(_.groupBy(Management.foundations, 'tier'),
            clist => clist.map(x => _.omit(x, 'tier')));

        setFoundationList(resFoundation)
    }, [])
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()

    const renderHeightWidth = (tier) => {
        let wh = null
        console.log(tier)
        if (tier == 1) {
            wh = {
                width: isMobile ? 150 : 200,
                height: isMobile ? 150 : 200,
            }
        }
        else if (tier == 2) {
            wh = {
                width: isMobile ? 120 : 170,
                height: isMobile ? 120 : 170,
            }
        } else {
            wh = {
                width: isMobile ? 100 : 150,
                height: isMobile ? 100 : 150,
            }
        }

        return wh
    }
    return (
        <>
            <Container style={{ padding: isMobile ? '1rem 1rem' : '8rem 0rem' }}>
                <Row className="mb-5">
                    <CustomHeading text={lang.TeacherPage.management.title} />
                </Row>
                {
                    Object.keys(foundationList).map((v, index) => {
                        return (
                            <>
                                <Row className="mb-5 justify-content-center">
                                    {
                                        foundationList[v]?.map((value) => {
                                            return (
                                                <>
                                                    <Col className="mb-5 text-center" sm={6} xs={6} md={2} style={{ height: isMobile ? 'fit-content' : 'auto', marginBottom: '1rem' }}>
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            className="text-center d-flex"
                                                            style={{
                                                                margin: '0 auto',
                                                                borderRadius: '100%',
                                                                height: isMobile ? 100 : 150,
                                                                width: isMobile ? 100 : 150,
                                                                objectFit: "cover"
                                                            }}
                                                            src={value.image} />
                                                        <div style={isMobile ? { fontSize: '0.9em' } : {}} className="mt-1 text-center">
                                                            <b>{value.name}</b>
                                                        </div>
                                                        <div style={isMobile ? { fontSize: '0.8em' } : {}} className="text-center">
                                                            {lang.TeacherPage.management.details[index].position}
                                                        </div>

                                                    </Col>

                                                </>
                                            )
                                        })
                                    }
                                </Row>
                            </>
                        )
                    })
                }

            </Container>
        </>
    )
}