import { render } from "@testing-library/react"
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row
} from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import Palette from "../utils/Palette"
import MockupImage from "../../assets/Admission/imageadmission1.png"
import { Icon } from "@iconify/react"
import CustomHeading from "../reusable/CustomHeading"
import { useOutletContext } from "react-router-dom"
import CustomButton from "../reusable/CustomButton"
import { useState } from "react"

export default function NewAdmissionPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    const PRESTASI = 1
    const TES_UJIAN = 2
    const [selectedAdmission, setSelectedAdmission] = useState(PRESTASI)
    const registrationData = [
        "Formulir Pendaftaran",
        `Foto Copy SKHUN/IJazah SMP`,
        `Pas Photo 3x4 = 4 Lembar`,
        `Pas Photo 2x3 = 2 Lembar`,
        `FotoCopy Kartu Keluarga`,
        `FotoCopy Raport SMP`
    ]

    const timelineList = [
        {
            title: "Tahap 1",
            description:
                "Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
            date: "5-21 Oktober 2023"
        },
        {
            title: "Tahap 2",
            description:
                "Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
            date: "5-21 Oktober 2023"
        },
        {
            title: "Tahap 3",
            description:
                "Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
            date: "5-21 Oktober 2023"
        },
        {
            title: "Tahap 4",
            description:
                "Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
            date: "5-21 Oktober 2023"
        },
        {
            title: "Tahap 5",
            description:
                "Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
            date: "5-21 Oktober 2023"
        },
        {
            title: "Tahap 6",
            description:
                "Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
            date: "5-21 Oktober 2023"
        }
    ]

    return (
        <>
            <Container
                style={{
                    paddingTop: isMobile ? "1rem" : "8rem",
                    paddingBottom: isMobile ? "1rem" : "0"
                }}
            >
                <Row className="mb-5">
                    <CustomHeading text={lang.NewAdmissionPage.section1.title} />
                </Row>
                <Row className={"mt-5 mb-5"}>
                    <Col md={6} className={"d-flex flex-column"}>
                        <div
                            style={
                                isMobile
                                    ? { fontSize: "0.9em", lineHeight: 2 }
                                    : { fontSize: "1em" }
                            }
                        >
                            {lang.NewAdmissionPage.section1.description}
                        </div>
                        <div className={`mt-3 ${isMobile ? 'mb-3' : ''}`}>
                            <CustomButton otherProps={{
                                style: isMobile ? { fontSize: '0.9em' } : {}
                            }} type={'secondary'} text={lang.NewAdmissionPage.downloadBrocureTitle} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover"
                            }}
                            src={MockupImage}
                        />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="mb-5">
                    <CustomHeading text={lang.NewAdmissionPage.section2.title} />
                </Row>

                <Row
                    style={isMobile ? { fontSize: "0.9em", lineHeight: 2 } : {}}
                    className="mb-5 flex-column"
                >
                    <Col>
                        <div style={{ fontSize: "1em" }}>
                            {lang.NewAdmissionPage.section2.description2}
                        </div>
                        <div className="mt-2" style={{ fontSize: "1em" }}>
                            {lang.NewAdmissionPage.section2.description3}
                        </div>
                    </Col>
                    <Col>
                        <div className="mt-4" style={{ fontSize: "1em" }}>
                            {lang.NewAdmissionPage.section2.requirements.map((v) => {
                                return (
                                    <>
                                        <Row>
                                            <Col
                                                className="w-auto mb-2"
                                                style={{ paddingRight: 0 }}
                                                md={1}
                                            >
                                                <Icon
                                                    icon={"material-symbols:circle"}
                                                    style={{ color: Palette.THEME_GREEN }}
                                                />
                                            </Col>
                                            <Col>
                                                <b>{v}</b>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            })}
                        </div>
                    </Col>
                    <Col className="mt-3">
                        <div style={{ fontSize: "1em" }}>
                            {lang.NewAdmissionPage.section2.description4}
                        </div>
                        <div className="mt-3" style={{ fontSize: "1em" }}>
                            {lang.NewAdmissionPage.section2.description5}
                        </div>
                    </Col>
                </Row>
            </Container>
            <svg
                style={{ marginTop: isMobile ? 20 : 0 }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 60"
                fill="none"
            >
                <path
                    width="100%"
                    height="100%"
                    d="M0 30.9096C619 -9.8169 831.5 -10.7866 1440 30.9096V60H0V30.9096Z"
                    fill="#F7F7F8"
                />

            </svg>
            <Container fluid style={{ background: "#F7F7F8", padding: 0 }}>
                <Container>
                    <Row className="mb-5">
                        <CustomHeading text={lang.NewAdmissionPage.section4.title} />
                    </Row>
                    <Row>
                        <Col sm={6} xs={6} md={2}>
                            <CustomButton
                                otherProps={{
                                    onClick: () => {
                                        setSelectedAdmission(PRESTASI)
                                    },
                                    style: isMobile ? { fontSize: "0.9em", padding: 10 } : {}
                                }}
                                type={selectedAdmission === PRESTASI ? "primary" : "secondary"}
                                text={lang.NewAdmissionPage.section4.admissionTypes.achievement.title}
                            />
                        </Col>
                        <Col sm={6} xs={6} md={2}>
                            <CustomButton
                                otherProps={{
                                    onClick: () => {
                                        setSelectedAdmission(TES_UJIAN)
                                    },
                                    style: isMobile ? { fontSize: "0.9em", padding: 10 } : {}
                                }}
                                type={selectedAdmission === TES_UJIAN ? "primary" : "secondary"}
                                text={lang.NewAdmissionPage.section4.admissionTypes.test.title}
                            />
                        </Col>
                    </Row>
                    <div className="mt-4 mb-5">
                        <b>
                            {lang.NewAdmissionPage.section4.description}{" "}
                            {selectedAdmission === PRESTASI ? lang.NewAdmissionPage.section4.admissionTypes.achievement.short : lang.NewAdmissionPage.section4.admissionTypes.test.short}
                        </b>
                    </div>
                </Container>
                <>
                    <div className="container py-2 mt-4 mb-4">
                        {selectedAdmission === PRESTASI && lang.NewAdmissionPage.section4.admissionTypes.achievement.timeline.map((value, key) => {
                            return (
                                <>
                                    <div
                                        className={`row no-gutters ${key % 2 === 0 ? "" : "flex-row-reverse"
                                            }`}
                                    >
                                        <Col className="py-2">
                                            <Card
                                                style={{
                                                    borderRadius: 5,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    borderBottom: 0,
                                                    borderRight: `3px solid ${Palette.THEME_GREEN}`
                                                }}
                                            >
                                                <CardBody>
                                                    <CardTitle
                                                        style={isMobile ? { fontSize: "1em" } : {}}
                                                    >
                                                        {value.title}
                                                    </CardTitle>
                                                    <CardText
                                                        style={isMobile ? { fontSize: "0.85em" } : {}}
                                                    >
                                                        {value.description}
                                                    </CardText>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col
                                            sm={2}
                                            xs={2}
                                            className="text-center flex-column d-sm-flex"
                                        >
                                            <Row>
                                                <Col>&nbsp;</Col>
                                                <Col>&nbsp;</Col>
                                            </Row>
                                            <h5
                                                style={isMobile ? { fontSize: "0.9em" } : {}}
                                                className="m-2"
                                            >
                                                <span
                                                    style={{
                                                        borderRadius: "100%",
                                                        background: Palette.THEME_GREEN,
                                                        height: isMobile ? 'auto' : 23
                                                    }}
                                                    className="badge"
                                                >
                                                    &nbsp;
                                                </span>
                                            </h5>
                                            <Row style={{ height: 100 }}>
                                                <Col className="border-right">&nbsp;</Col>
                                                <Col>&nbsp;</Col>
                                            </Row>
                                        </Col>

                                        <Col className="py-2"></Col>
                                    </div>
                                </>
                            )
                        })}
                        {selectedAdmission === TES_UJIAN && lang.NewAdmissionPage.section4.admissionTypes.test.timeline.map((value, key) => {
                            return (
                                <>
                                    <div
                                        className={`row no-gutters ${key % 2 === 0 ? "" : "flex-row-reverse"
                                            }`}
                                    >
                                        <Col className="py-2">
                                            <Card
                                                style={{
                                                    borderRadius: 5,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    borderBottom: 0,
                                                    borderRight: `3px solid ${Palette.THEME_GREEN}`
                                                }}
                                            >
                                                <CardBody>
                                                    <CardTitle
                                                        style={isMobile ? { fontSize: "1em" } : {}}
                                                    >
                                                        {value.title}
                                                    </CardTitle>
                                                    <CardText
                                                        style={isMobile ? { fontSize: "0.85em" } : {}}
                                                    >
                                                        {value.description}
                                                    </CardText>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col
                                            sm={2}
                                            xs={2}
                                            className="text-center flex-column d-sm-flex"
                                        >
                                            <Row>
                                                <Col>&nbsp;</Col>
                                                <Col>&nbsp;</Col>
                                            </Row>
                                            <h5
                                                style={isMobile ? { fontSize: "0.9em" } : {}}
                                                className="m-2"
                                            >
                                                <span
                                                    style={{
                                                        borderRadius: "100%",
                                                        background: Palette.THEME_GREEN,
                                                        height: 23
                                                    }}
                                                    className="badge"
                                                >
                                                    &nbsp;
                                                </span>
                                            </h5>
                                            <Row style={{ height: 100 }}>
                                                <Col className="border-right">&nbsp;</Col>
                                                <Col>&nbsp;</Col>
                                            </Row>
                                        </Col>

                                        <Col className="py-2"></Col>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </>

                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 1440 60"
                    fill={Palette.THEME_BG_BEIGE}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        width="100%"
                        clip-rule="evenodd"
                        d="M0 32.8545L39.6 27.1991C80.4 21.5438 159.6 10.2332 240 4.57784C320.4 -1.07748 399.6 -1.07748 480 2.31571C560.4 5.70891 639.6 12.4953 720 11.3642C800.4 10.2332 879.6 1.18465 960 6.83997C1040.4 12.4953 1119.6 32.8545 1200 36.2477C1280.4 39.6408 1359.6 26.0681 1400.4 19.2817L1440 12.4953V60H1400.4C1359.6 60 1280.4 60 1200 60C1119.6 60 1040.4 60 960 60C879.6 60 800.4 60 720 60C639.6 60 560.4 60 480 60C399.6 60 320.4 60 240 60C159.6 60 80.4 60 39.6 60H0V32.8545Z"
                        fill={Palette.THEME_BG_BEIGE}
                    />
                </svg>
            </Container>
        </>
    )
}
