import { Icon } from "@iconify/react"
import { Col, Container, Row } from "react-bootstrap"
import Palette from "../utils/Palette"

export default function ContactPage() {
    const renderHero = () => {
        return (
            <div
                style={{
                    backgroundColor: Palette.THEME_BLUE,
                    width: '100%',
                    color: 'white',
                    padding: '4rem 0rem'
                }}
            >
                <Container style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col
                        lg={7}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1.5rem',
                        }}
                    >
                        <Row
                            style={{
                                textAlign: 'center',
                                fontSize: '1.75rem',
                                fontWeight: 700,
                                alignItems: 'center',
                            }}>
                            Get in Touch
                        </Row>
                        <Row

                            style={{
                                alignSelf: 'stretch',
                                color: '#E0E0E0',
                                textAlign: 'center',
                                fontSize: '0.875rem',
                                fontWeight: 400,
                                padding: '0 15px 0 15px'
                            }}
                        >
                            {/* Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem. */}
                        </Row>
                    </Col>
                </Container>
            </div >
        )
    }

    return (
        <>
            {renderHero()}

            <Container>
                <Row className="mt-5 mb-5">
                    <Col md={6} sm={12}>
                        <div className="contact-left">
                            <div className="d-flex contact-icon" style={{ alignItems: 'center', justifyContent: "center" }}>
                                <div className="contact-icon-container">
                                    <div className="contact-icon-box"><Icon icon={"ion:location-outline"} ></Icon></div>
                                </div>
                                <div className="contact-title" style={{ flex: 1 }}>
                                    <div>Location</div>
                                </div>
                            </div>
                            <div className="mt-3 contact-content">
                                <p style={{ fontWeight: 800, marginBottom: 0 }}>Bina Steel Group</p>
                                Jl. P. Jayakarta 123 Komp Melawan no. 28/3, Jakarta (10730)
                            </div>
                        </div>

                    </Col>
                    <Col md={6} sm={12}>
                        <div className="mb-5">
                            <div className="d-flex contact-icon">
                                <div className="contact-icon-container">
                                    <div className="contact-icon-box"><Icon icon={"uiw:mail-o"} ></Icon></div>
                                </div>
                                <div className="contact-title" style={{ flex: 1 }}>
                                    <div>Email</div>
                                </div>
                            </div>
                            <div className="mt-3 contact-content">
                                admin-marketing01@binasteelpipe.com
                            </div>
                        </div>
                        <div className="mb-5">
                            <div className="d-flex contact-icon">
                                <div className="contact-icon-container">
                                    <div className="contact-icon-box"><Icon icon={"carbon:phone"} ></Icon></div>
                                </div>
                                <div className="contact-title" style={{ flex: 1 }}>
                                    <div>Call</div>
                                </div>
                            </div>
                            <div className="mt-3 contact-content">
                                Office: 021 – 624 -7035
                            </div>
                        </div>
                        <div className="mb-5">
                            <div className="d-flex contact-icon">
                                <div className="contact-icon-container">
                                    <div className="contact-icon-box"><Icon icon={"mdi:web"} ></Icon></div>
                                </div>
                                <div className="contact-title" style={{ flex: 1 }}>
                                    <div>Website</div>
                                </div>
                            </div>
                            <div className="mt-3 contact-content">
                                www.binasteelgroup.com
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}