import {
	Button,
	Card,
	CardBody,
	CardImg,
	Col,
	Container,
	Image,
	Row
} from "react-bootstrap"
import Palette from "../utils/Palette"
import CountUp from "react-countup"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
// import required modules
import {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	Keyboard,
	Autoplay
} from "swiper/modules"
import profile from "../../assets/profile.png"
import principalImage from "../../assets/teacher/Ir. Aminah.jpg"
import heroBackground from "../../assets/header-background.jpg"

import aboutImage1 from "../../assets/about/banner_1_tall.jpg"
import aboutImage2 from "../../assets/about/banner_2_small.jpg"
import aboutImage3 from "../../assets/about/banner_3_small.jpg"
import aboutImage4 from "../../assets/about/banner_4_tall.jpg"

import { Icon } from "@iconify/react"
import CustomHeading from "../reusable/CustomHeading"

import { Link, useNavigate, useOutletContext } from "react-router-dom"
import CustomButton from "../reusable/CustomButton"
import CustomIcon from "../utils/CustomIcon"
import Facility from "../models/Facility"
import noImage from '../../assets/noimage.png'
import Activity from "../models/Activity"
import {useState} from "react";

export default function HomePage({ }) {
	const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
	const navigate = useNavigate()
	const listImage = [aboutImage1, aboutImage2, aboutImage3, aboutImage4]
	const [showMore, setShowMore] = useState(false)
	const items = Array.from({ length: 6 }).map((_, index) => (
		<div
			key={index}
			style={{
				height:
					index % 2
						? isMobile
							? "100px"
							: "300px"
						: isMobile
							? "150px"
							: "450px",
				backgroundImage: `url("${listImage[Math.floor(Math.random() * 3) + 1]
					}")`,
				margin: "10px",
				borderRadius: "8px"
			}}
		/>
	))

	const testimoniList = [
		{
			message:
				".",
			name: "Ibu Rahma",
			position: "Orang tua siswa",
			image: profile
		},
		{
			message:
				".",
			name: "Bapak Budi",
			position: "Orang tua siswa",
			image: profile
		}
	]

	const renderHeroSection = () => {
		return (
			<>
				<div
					className={"d-flex align-items-center"}
					style={{
						height: isMobile ? "90vh" : "85vh",
						background: `linear-gradient(0deg, rgba(13, 174, 86, 0.56) 0%, rgba(13, 174, 86, 0.56) 100%), url(${heroBackground}) lightgray 50% / cover no-repeat`,
						// background: `background: linear-gradient(0deg, rgba(13, 174, 86, 0.56) 0%, rgba(13, 174, 86, 0.56) 100%)`,
						color: "white",
						paddingTop: !isMobile ? 160 : 60,
						paddingBottom: isMobile ? null : 150
					}}
				>
					<Container>
						<Row>
							<Col
								style={{
									// marginTop: 'auto',
									marginBottom: "auto",
									textAlign: !isMobile ? "left" : "center"
								}}
								sm={12}
								xs={12}
								md={12}
								lg={6}
							>
								<b
									style={{
										fontWeight: 800,
										lineHeight: "1.4em",
										fontSize: !isMobile ? "2.5em" : "2em"
									}}
								>
									{lang.HomePage.welcome.title}
								</b>
								<div
									style={{ padding: "0 5px 0 5px", fontWeight: 600 }}
									className={"mt-5 mb-3"}
								>
									{lang.HomePage.welcome.description}
								</div>
								<CustomButton
									otherProps={{
										onClick: () => {
											navigate("/admission")
										}
									}}
									type={"secondary"}
									text={lang.HomePage.welcome.button}
								/>
							</Col>
						</Row>
					</Container>
				</div>
				<div
					style={{ position: "relative", bottom: !isMobile ? 35 : 15 }}
					className="p-0 m-0"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 1440 60"
						fill="none"
					>
						<path
							width="100%"
							height="100%"
							d="M0 0C581.004 32.7212 896.996 33.906 1440 0V60H0V0Z"
							fill={Palette.THEME_BG_BEIGE}
						/>
					</svg>
				</div>
			</>
		)
	}
	const renderPrincipalSection = () => {
		return (

			<Container className={`pb-5`}>
				<Row>
					<Col className={"d-flex flex-column"} md={12}>
						<CustomHeading text={lang.HomePage.greetings.title} />
					</Col>
				</Row>
				<Row className={"mt-5 justify-content-around"}>
					<Col
						className={isMobile ? "text-center" : "text-center"}
						lg={3}
						md={12}
						sm={12}
						xs={12}
					>
						<LazyLoadImage
							effect="blur"
							style={{
								textAlign: "center",
								width: 250,
								height: 250,
								borderRadius: "100%",
								objectFit: "cover"
							}}
							alt={"about-section-image"}
							src={principalImage}
						/>

						<h4
							style={{
								textAlign: "center",
								fontWeight: 800,
								fontSize: "1.4em",
								marginTop: 20
							}}
						>
							Ir. Aminah
						</h4>
						<p style={{ textAlign: "center" }}>
							{lang.HomePage.greetings.position}
						</p>
					</Col>

					<Col sm={12} md={12} xs={12} lg={8} className={"d-flex flex-column justify-content-center"}>
						<div style={{ fontSize: "1em", lineHeight: 2 }}>
							{showMore ? lang.HomePage.greetings.paragraph : lang.HomePage.greetings.paragraphs_short}
						</div>
						<Link
							onClick={() => {
								setShowMore(!showMore)
							}}
							style={{
								color: Palette.THEME_GREEN,
								fontWeight: 600,
								fontSize: "1em"
							}}
							className="text-decoration-none"
						>
							{showMore ? lang.HomePage.about.less :  lang.HomePage.about.more} <Icon icon={`mingcute:arrow-${showMore ? 'left' : 'right'}-line`} />
						</Link>
					</Col>
				</Row>
			</Container>

		)
	}

	const renderAboutUsSection = () => {
		return (
			<div>
				<Container style={{ marginTop: isMobile ? 0 : 10 }}>
					<Row className=" justify-content-around">
						<Col lg={3} xl={3} md={12}>
							<CustomHeading text={lang.HomePage.about.title} />
						</Col>
						<Col lg={8} xl={8} md={12}>
							<div style={{ fontSize: "1em", lineHeight: 2 }}>
								{lang.HomePage.about.description}
							</div>
							<Link
								style={{
									color: Palette.THEME_GREEN,
									fontWeight: 600,
									fontSize: "1em"
								}}
								className="text-decoration-none"
								to={"/about"}
							>
								{lang.HomePage.about.more} <Icon icon={"mingcute:arrow-right-line"} />
							</Link>
						</Col>
					</Row>
					<Row className={"mt-5 mb-5"}>
						<div className="grid-wrapper">
							<div className="tall">
								<img
									effect="blur"
									className="image-mansonry"
									src={aboutImage1}
									alt="images-man1"
								/>
							</div>
							<div className="d-block">
								<div className="mb-2">
									<img
										effect="blur"
										className="image-child"
										src={aboutImage2}
										alt="images-man2"
									/>
								</div>
								<div>
									<img
										effect="blur"
										className="image-child"
										src={aboutImage3}
										alt="images-man3"
									/>
								</div>
							</div>
							<div className="tall">
								<img
									effect="blur"
									className="image-mansonry"
									src={aboutImage4}
									alt="images-man4"
								/>
							</div>
						</div>
					</Row>
				</Container>
			</div>
		)
	}

	const renderOurProductsSection = () => {
		return (
			<div>
				<Container style={{ marginTop: isMobile ? 10 : 100 }}>
					<Row>
						<Col
							className={
								"d-flex flex-column align-items-center justify-content-center"
							}
							md={12}
						>
							<CustomHeading text={"Tenaga Pendidik"} />
							<p>Lorem ipsum sit dolor amet lorem ipsum sit</p>
						</Col>
					</Row>

					{/* <Row className={"mt-5 mb-5"}>

                    {

                        Teacher.map((v) => {
                            return (
                                <>
                                    <Col className="text-center" md={2} sm={6} xs={6} style={{ height: isMobile ? 'fit-content' : 'auto', marginBottom: '1rem' }}>
                                        <LazyLoadImage
                                            effect="blur"
                                            className="text-center d-flex"
                                            style={{
                                                margin: '0 auto',
                                                borderRadius: '100%',
                                                height: 150,
                                                width: 150,
                                                objectFit: "cover"
                                            }}
                                            src={v.image} />
                                        <div className="mt-1 text-center">
                                            <p style={{ fontWeight: 600, fontSize: isMobile ? '0.9em' : '1.1em' }}>{v.name}</p>
                                        </div>
                                        <div className="text-center">
                                            <p style={{ fontWeight: 500, fontSize: isMobile ? '0.8em' : '1em' }}>{v.position}</p>
                                        </div>

                                    </Col>

                                </>
                            )
                        })
                    }
                    <Link style={{ color: Palette.THEME_GREEN, fontWeight: 700, fontSize: "1em" }} className="text-decoration-none text-center mt-3" to={'/teacher'}>Selengkapnya  <Icon icon={"mingcute:arrow-right-line"} /></Link>

                </Row> */}
				</Container>
			</div>
		)
	}
	const renderServices = () => {
		return (
			<>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1440 60"
					fill="none"
				>
					<path
						width="100%"
						height="100%"
						d="M0 30.9096C619 -9.8169 831.5 -10.7866 1440 30.9096V60H0V30.9096Z"
						fill={'#F7F7F8'}
					/>
				</svg>
				<Container fluid style={{ background: "#F7F7F8" }}>
					<Container>
						<Row className="mt-4 mb-5">
							<Col
								className={
									"d-flex flex-column align-items-center justify-content-center"
								}
								md={12}
							>
								<CustomHeading text={lang.HomePage.facilities.title} />
							</Col>
						</Row>
						<Row className="mt-5">
							{lang.HomePage.facilities.list.map((v, index) => {
								return (
									<>
										<Col className="mb-4" lg={3} xl={3} md={4} sm={6} xs={6}>
											<CustomIcon
												clientTextSize={isMobile && "0.9em"}
												clientHeight={isMobile && "1.75rem"}
												clientWidth={isMobile && "1.75rem"}
												clientText={v?.name}
												clientIcon={v.icon}
											/>
										</Col>
									</>
								)
							})}
						</Row>
					</Container>
					<Container className="mb-5">
						<Row className={`mt-${isMobile ? '4' : '4'} mb-${isMobile ? '5' : '5'}`}>
							<Col
								className={
									"d-flex flex-column align-items-center justify-content-center"
								}
								md={12}
							>
								<CustomHeading text={lang.HomePage.activities.title} />
								<p>
									{lang.HomePage.activities.description}
								</p>


							</Col>
						</Row>
						<Row className="mt-1">
							<Swiper
								slidesPerView={5}
								spaceBetween={6}
								keyboard={{
									enabled: true
								}}
								breakpoints={{
									375: {
										slidesPerView: 2,
									},
									425: {
										slidesPerView: 2,
									},
									576: {
										slidesPerView: 2,
									},
									768: {
										slidesPerView: 3,

									},
									1020: {
										slidesPerView: 6,

									},
								}}

								pagination={{
									type: "progressbar",
									paginationClickable: true,
									dynamicBullets: true,
									clickable: true,
									keyboard: {
										enabled: true
									}
									// progressbarOpposite: true
								}}
								autoplay={{
									delay: 3000,
									disableOnInteraction: false
								}}
								modules={[
									Navigation,
									Pagination,
									Scrollbar,
									A11y,
									Keyboard,
									Autoplay
								]}
								className="mySwiper"
							>
								{Activity.map((v, index) => {
									return (
										<>
											<SwiperSlide
												loop={true}
												loopedSlides={true}
											>
												<div class="img-activity">
													<Image
														style={{
															minHeight: isMobile ? 250 : 250,
															objectFit: "cover"
														}}
														className="img-responsive img-fluid"
														src={v.image}
													></Image>
													<div class="overlay">
														<div
															className="caption-title"
															style={{
																color: "#FFF",
																fontWeight: 800,
																textAlign: "center",
																paddingTop: 20,
																paddingLeft: 3,
																paddingRight: 3,
																marginTop: "auto",
																marginBottom: "auto"
															}}
														>
															<div className="mb-3">
																{lang.HomePage.activities.list[index]?.name}
															</div>

															<div
																className="pb-1"
																style={{ fontSize: '0.7em', background: 'transparent !important' }}
															>
																{lang.HomePage.activities.list[index]?.description}
															</div>
														</div>

													</div>
												</div>
											</SwiperSlide>

										</>
									)
								})}

							</Swiper>

						</Row>
					</Container>
					{/* <Container className="mb-5">
                        <Row className="mt-4 mb-5">
                            <Col className={"d-flex flex-column align-items-center justify-content-center"} md={12}>
                                <CustomHeading text={"Ekstrakulikuler"} />
                                <p>Lorem ipsum sit dolor amet lorem ipsum sit</p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            {
                                Extrakulikuler.map((v) => {
                                    return (
                                        <>
                                            <Col className="mb-4" md={3} lg={3} xl={3} sm={6} xs={6}>
                                                <Image style={{ minHeight: isMobile ? 150 : 350 }} className="img-responsive img-fluid" src={noImage}></Image>
                                                <p style={{ "position": "relative", "bottom": "15%", "left": "5%", "color": "#FFF", "fontWeight": "800" }}>{v.name}</p>
                                            </Col>
                                        </>
                                    )
                                })
                            }


                        </Row>
                    </Container> */}
				</Container>
			</>
		)
	}

	const renderTestimoni = () => {
		return (
			<>
				<Container
					style={{
						color: Palette.THEME_WHITE,
						background: `linear-gradient(0deg, rgba(13, 174, 86, 0.56) 0%, rgba(13, 174, 86, 0.56) 100%), url(${heroBackground}) lightgray 50% / cover no-repeat`
					}}
					fluid
					className="p-5"
				>
					<Row
						className={`${!isMobile
							? "justify-content-center"
							: "justify-content-between p-3 mt-5"
							}`}
						style={{ alignItems: "flex-end" }}
					>
						<Col
							className={"d-flex flex-column align-items-center mt-2 mb-3"}
							lg={4}
							md={4}
							xs={12}
						>
							<div
								style={{
									fontWeight: 800,
									fontSize: !isMobile ? "4.75em" : "1.7em"
								}}
							>
								<CountUp end={500} duration={2} />
							</div>
							<div style={{ fontSize: "1em", fontWeight: "bold" }}>
								{lang.HomePage.facts.students}
							</div>
						</Col>
						<Col
							className={"d-flex flex-column align-items-center mt-2 mb-3"}
							lg={4}
							md={4}
							xs={12}
						>
							<div
								style={{
									fontWeight: 800,
									fontSize: !isMobile ? "4.75em" : "1.7em"
								}}
							>
								<CountUp end={25} duration={2} />
							</div>
							<div style={{ fontSize: "1em", fontWeight: "bold" }}>
								{lang.HomePage.facts.teachers}
							</div>
						</Col>
						<Col
							className={"d-flex flex-column align-items-center mt-2 mb-3"}
							lg={4}
							md={4}
							xs={12}
						>
							<div
								style={{
									fontWeight: 800,
									fontSize: !isMobile ? "4.75em" : "1.7em"
								}}
							>
								<CountUp end={200} duration={2} />
							</div>
							<div style={{ fontSize: "1em", fontWeight: "bold" }}>
								{lang.HomePage.facts.admitted}
							</div>
						</Col>
					</Row>
				</Container>
				<Container style={isMobile ? {} : { marginTop: 100 }}>
					<Row className="mb-3 mt-5">
						<CustomHeading text={lang.HomePage.testimonial.title}></CustomHeading>
					</Row>
					<Row className={isMobile ? `mb-3` : ""}>
						<Col className={`${isMobile && " order-2"}`} xs={12} sm={12} md={12} lg={8} xl={8}>
							<Swiper
								keyboard={{
									enabled: true
								}}
								pagination={{
									type: "progressbar",
									paginationClickable: true,
									dynamicBullets: true,
									clickable: true,
									keyboard: {
										enabled: true
									}
									// progressbarOpposite: true
								}}
								autoplay={{
									delay: 3000,
									disableOnInteraction: false
								}}
								modules={[
									Navigation,
									Pagination,
									Scrollbar,
									A11y,
									Keyboard,
									Autoplay
								]}
								className="mySwiper"
							>
								<Card className="border-0">
									<CardBody>
										{lang.HomePage.testimonial.list.map((value, index) => {
											return (
												<>
													<SwiperSlide>
														<Row className="mb-3">
															<p>{`" ${value.message} "`}</p>
														</Row>
														<Row
															style={{ gap: 20, lineHeight: 1 }}
															className="mb-5"
														>
															<Col xs={2} md={1}>
																<LazyLoadImage
																	style={{ width: 60, height: 60 }}
																	className="rounded-circle"
																	src={value.image ? value.image : noImage}
																/>
															</Col>
															<Col xs={8} md={10}>
																<p style={{ fontWeight: 800 }}>{value.name}</p>
																<p>{value.position}</p>
															</Col>
														</Row>
													</SwiperSlide>
												</>
											)
										})}
									</CardBody>
								</Card>
							</Swiper>
						</Col>
						<Col
							className={`mx-auto ${isMobile && "d-none order-1"}`}
							md={3}
							sm={12}
							xs={12}
						>
							<LazyLoadImage
								style={{
									position: "relative",
									bottom: "45%",
									height: 370
								}}
								className="img-fluid"
								src={aboutImage1}
							></LazyLoadImage>
						</Col>
					</Row>
				</Container>
			</>
		)
	}

	return (
		<div style={{
			background: Palette.THEME_BG_BEIGE
		}} className={"d-flex flex-column"}>
			{renderHeroSection()}
			{renderPrincipalSection()}
			{renderAboutUsSection()}
			{/* {renderOurProductsSection()} */}
			{renderServices()}
			{renderTestimoni()}
		</div>
	)
}
