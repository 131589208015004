import { useRoutes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import HomeLayout from "./layout/HomeLayout";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import NewAdmissionPage from "./pages/NewAdmissionPage";
import HistoryPage from "./pages/HistoryPage";
import GalleryPage from "./pages/GalleryPage";
import GalleryPageV2 from "./pages/GalleryPageV2";
import TeacherPage from "./pages/TeacherPage";
import FaqPage from "./pages/FaqPage";
import Ekstrakulikuler from "./pages/EkstrakulikulerPage";
import FoundationPage from "./pages/FoundationPage";
import StudentshipPage from "./pages/StudentshipPage";
import ActivityPage from "./pages/ActivityPage";
import OsisPage from "./pages/OsisPage";
import AchievementPage from "./pages/AchievementPage";

export default function Router() {


    let isLoggedIn = !!localStorage.getItem("token")

    let tempRoutes = [
        {
            path: '',
            element: <HomeLayout />,
            children: [
                { path: '', element: <HomePage /> },
                { path: '/about', element: <AboutPage /> },
                { path: '/teacher', element: <TeacherPage /> },
                { path: '/foundation', element: <FoundationPage /> },
                { path: '/studentship/osis', element: <OsisPage /> },
                { path: '/studentship/achievement', element: <AchievementPage /> },
                { path: '/studentship/extracurricular', element: <StudentshipPage page="extracurricular" /> },
                { path: '/studentship/activity', element: <ActivityPage /> },
                { path: '/admission', element: <NewAdmissionPage /> },
                { path: '/history', element: <HistoryPage /> },
                { path: '/gallery', element: <GalleryPageV2 /> },
                { path: '/ekstrakulikuler', element: <Ekstrakulikuler /> },
                // { path: '/gallery-v2', element: <GalleryPageV2 /> },
                { path: '/faq', element: <FaqPage /> },
                { path: '/contact', element: <ContactPage /> },
            ],
        }
    ]

    const routes = useRoutes(tempRoutes);

    return routes;
}
