import { Outlet } from "react-router-dom";
import Palette from "../utils/Palette";
import Header from "../reusable/Header";
import Footer from "../reusable/Footer";
import { useState, useEffect } from "react";
import langId from "../../utils/lang/id"
import langEn from "../../utils/lang/en"

export default function HomeLayout({ }) {
    const [presets,setPresets] = useState('FULL')

    const [isMobile, setIsMobile] = useState(window.innerWidth < 769)
    const [lang, setLang] = useState(langId)
    useEffect(() => {
        const handleWindowResize = () => {
            setIsMobile(window.innerWidth < 769)

                 if(window.innerWidth <= 320){
                     setPresets('MOBILE_S')
                 }else if(window.innerWidth <= 37){
                     setPresets('MOBILE_M')
                 }else if(window.innerWidth <= 425){
                     setPresets('MOBILE_L')
                 }else if(window.innerWidth <= 768){
                     setPresets('TABLET')
                 }else if(window.innerWidth <= 1024){
                     setPresets('LAPTOP_M')
                 }else{
                     setPresets('FULL')
                 }
        };

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    return <div>
        <Header isMobile={isMobile} presets={presets} lang={lang} setLang={setLang} setPresets={setPresets} />
        <div style={{ marginTop: isMobile ? 0 : 0 }}>
            <Outlet context={[isMobile, setIsMobile, lang, setLang, presets, setPresets]} />
        </div>
        <Footer isMobile={isMobile} lang={lang} setLang={setLang} presets={presets} setPreset={setPresets} />
    </div>
}