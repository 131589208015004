import andika_acv from '../../assets/achievement/andhika_unibraw.jpeg'
import salsabila_acv from '../../assets/achievement/salsabila_udayana.jpeg'
import gustiara_acv from '../../assets/achievement/gustiara_upn.jpeg'
import ibrahim_acv from '../../assets/achievement/ibrahim_haluoleo.jpeg'
import marchela_acv from '../../assets/achievement/marchela_unj.jpeg'
import maulana_acv from '../../assets/achievement/maulana_yusuf_unila.jpeg'
import devira_acv from '../../assets/achievement/devira_udayana.jpeg'
import nail_acv from '../../assets/achievement/nail_unj.jpeg'
import rakha_acv from '../../assets/achievement/rakha_catra_unj.jpeg'
import raffa_acv from '../../assets/achievement/raffa_unj.jpeg'
import siti_zahra_acv from '../../assets/achievement/siti_zahira_unnes.jpeg'
import syahrini_acv from '../../assets/achievement/syahrini_undip.jpeg'
import genggam_acv from '../../assets/achievement/genggam_unibraw.jpeg'
import osisImage1 from '../../assets/osis/osisImage1.png'
import osisImage2 from '../../assets/osis/osisImage2.png'
import osisImage3 from '../../assets/osis/osisImage3.png'

import irAminah from '../../assets/teacher/Ir. Aminah.jpg'
import maretha from '../../assets/teacher/Maretha Murti Kundari, S.H.jpg'
import suswati from '../../assets/teacher/Suswati, M.Pd.jpg'
import lin from '../../assets/teacher/Iin Chintya Asmara, M.Pd.jpg'
import yudi from '../../assets/teacher/Yudi Rianto, S.Pd.jpg'
import andi from '../../assets/teacher/Andi Yuniawan, B.A.jpg'
import arofik from '../../assets/teacher/Arrofik Showi, S.Ag.jpg'
import sutrimo from '../../assets/teacher/Sutrimo, S.Pd.jpg'
import muarip from '../../assets/teacher/Muarip, S.Pd.jpg'
import bimansyah from '../../assets/teacher/Bimasyah Sihite, S.Pd.jpg'
import dianita from '../../assets/teacher/Dianita Wulansari, S.Pd.jpg'
import sri_aminah from '../../assets/teacher/Dra. Sri Aminah.jpg'
import doddy from '../../assets/teacher/Dody Riswanto, M.Pd.jpg'
import maharni from '../../assets/teacher/Muharni Afridita, S.Pd.jpg'
import dita from '../../assets/teacher/Dita Mulyawati, S.Pd.jpg'
import hairunnisyah from '../../assets/teacher/Hairunnisyah, S.Pd.jpg'
import yoto from '../../assets/teacher/Yoto, S.E.jpg'
import rita from '../../assets/teacher/Rita Kusuma Dewi, S.Pd.jpg'
import chairil from '../../assets/teacher/ChairiSH.jpg'
import ishadi from '../../assets/teacher/Ishadi, S.E.jpg'
import eko from '../../assets/teacher/Eko Prasetyo.jpg'
import kusyadi from '../../assets/teacher/Kusyadi.jpg'
import noImage from '../../assets/noimage.png'

export default {
	MENU_HEADER: [
		{ name: "Beranda", path: "/" },
		{
			name: "Tentang Sekolah", path: "/about", submenu: [
				{
					name: "Sejarah", path: '/history'
				},
				{
					name: "Informasi Umum", path: '/about'
				},
				{
					name: "Visi dan Misi", path: '/about?ref=vision'
				},
				{
					name: "Core Value", path: '/about?ref=core-value'
				},
				// {
				//     name: "Ekstrakulikuler", path: '/ekstrakulikuler'
				// },
				{
					name: "Galeri Sekolah", path: '/gallery'
				},

			]
		},
		{
			name: "Tenaga Pendidik", path: "/teacher",

			// submenu: [
			// 	{
			// 		name: "Guru", path: '/teacher'
			// 	},
			// 	{
			// 		name: "Anggota Yayasan", path: '/foundation'
			// 	},

			// ]
		},
		{
			name: "Kesiswaan", path: "#", submenu: [
				{
					name: "OSIS", path: '/studentship/osis'
				},
				{
					name: "Prestasi Siswa", path: '/studentship/achievement'
				},
				{
					name: "Kegiatan Sekolah", path: '/studentship/activity'
				},


			]
		},
		{ name: "Penerimaan Peserta Didik Baru (PPDB)", path: "/admission" },
		{ name: "FAQ", path: "/faq" },
		// {name : "Our Services", path : "/services"},
	],

	MENU_FOOTER: [
		{ name: "Beranda", path: "/", group: 1 },
		{ name: "Tenaga Pendidik", path: "/teacher", group: 1 },
		{ name: "Penerimaan Peserta Didik Baru (PPDB)", path: "/admission", group: 1 },
		{ name: "FAQ", path: "/faq", group: 1 },
		{ name: "Alumni", path: "https://www.facebook.com/groups/65729086314/", group: 1, rawLink: true },
		{
			name: "Tentang Sekolah", path: "/about", isTitle: true, group: 2
		},
		{
			name: "Sejarah", path: '/history', group: 2
		},
		{
			name: "Informasi Umum", path: '/about', group: 2
		},
		{
			name: "Visi dan Misi", path: '/about?ref=vision', group: 2
		},
		{
			name: "Core Value", path: '/about?ref=core-value', group: 2
		},
		// {
		//     name: "Ekstrakulikuler", path: '/ekstrakulikuler'
		// },
		{
			name: "Galeri Sekolah", path: '/gallery', group: 2
		},

	],
	HistoryPage: {
		title: "Sejarah",
		paragraphs: [
			"SMA Sunda Kelapa, yang berdiri sejak 8 Agustus 1963, merupakan salah satu lembaga pendidikan yang menjadi bagian integral dari Yayasan Pendidikan Sunda Kelapa. Yayasan ini didirikan dan dipimpin dengan penuh dedikasi oleh Ny. Hj. S. Moerdono, seorang tokoh pendidikan terkemuka pada zamannya. Proses pembentukan lembaga pendidikan ini juga mendapat kontribusi berharga dari Ibu Pia Alisyahbana dan Ibu Popy Awaludin, yang berperan sebagai pembina yang berdedikasi.",
			"SMA Sunda Kelapa diresmikan oleh Ibu Fatmawati Soekarno, seorang Ibu Negara pertama Republik Indonesia. Dengan semangat luar biasa dalam meningkatkan kualitas pendidikan nasional, sekolah ini terus berupaya memberikan kontribusi yang signifikan. Untuk mencapai tujuan tersebut, SMA Sunda Kelapa telah mendapatkan dukungan kuat dari sejumlah individu berpengaruh dalam masyarakat.",
			"Tidak hanya bergantung pada dukungan eksternal, SMA Sunda Kelapa juga dikenal memiliki staf pengajar yang memiliki dedikasi tinggi, pengalaman yang luas, dan keahlian yang mendalam di bidang pendidikan. Mereka telah memainkan peran penting dalam membentuk dan mengembangkan peserta didiknya menjadi individu yang berkualitas dan berpotensi.",
			"Sejak berdiri dan menjadi salah satu sekolah swasta pertama serta saat ini dapat dikatakan sebagai salah satu sekolah swasta tertua, SMA Sunda Kelapa telah menjadi salah satu lembaga pendidikan yang berkomitmen untuk menjalankan misi penting dalam memajukan pendidikan di Indonesia. Melalui dedikasi dan komitmen yang tinggi, sekolah ini terus menjadi salah satu pilar pendidikan yang berpengaruh dalam pembentukan generasi muda yang berkualitas dan siap menghadapi masa depan."
		]
	},
	AboutPage: {
		title: "Tentang Sekolah",
		accreditations : {
			num_title: (<strong>Akreditasi: </strong>),
			num_value: 'Unggul (Nilai: 92)',
			established_title: (<strong>Tanggal didirikan: </strong>),
			established_value: '8 Agustus 1963',
			established_by_title: <strong>Pendiri: </strong>,
			established_by_value: 'Sjamsidar Moerdono',
		},
		mission: {
			title: "Misi",
			paragraphs: [
				"Meningkatkan keimanan dan ketaqwaan kepada Tuhan Yang Maha Esa",
				"Menjunjung tinggi nilai kejujuran dan selalu rendah hati",
				`Mewujudkan kepedulian terhadap lingkungan`,
				`Membentuk siswa yang bertanggung jawab, gigih dan kreatif`,
				`Menciptakan lulusan yang intelektual dan humanis`
			]
		},
		vision: {
			title: "Visi",
			paragraphs: [
				{
					name: "Taat beragama, santun berperilaku dan unggul dalam prestasi",
					icon: "fa-solid:praying-hands"
				}
			]
		},
		coreValues: {
			title: "Core Values • BERPRESTASI",
			paragraphs: [
				{
					title: "Beriman",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>
									Meningkatkan keimanan dan ketaqwaan terhadap Tuhan YME.{" "}
								</li>
								<li>Melaksanakan ibadah sesuai keyakinan masing-masing.</li>
								<li>Memperingati hari besar keagamaan.</li>
							</ul>
						</>
					)
				},
				{
					title: "Percaya Diri",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>Berprestasi dalam bidang akademik maupun non-akademik. </li>
								<li>
									Meraih prestasi dalam kompetisi baik di sekolah ataupun diluar
									sekolah di bidang sains, kreasi, seni, dan olahraga.
								</li>
								<li>Berani mengemukakan pendapat. </li>
								<li>Bertanggung jawab sesuai tugasnya. </li>
							</ul>
						</>
					)
				},
				{
					title: "Rendah Hati",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>Menjunjung tinggi nilai-nilai kejujuran. </li>
								<li>Bergotong royong dan bekerja sama dalam kebaikan</li>
								<li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
								<li>Tidak sombong dan menghargai pendapat orang lain.</li>
							</ul>
						</>
					)
				},
				{
					title: "Santun",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>Menanamkan sikap sopan dan santun di dalam pergaulan.</li>
								<li>Bertutur kata sopan kepada siapapun. </li>
								<li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
								<li>
									Selalu disiplin dalam melaksanakan tata-tertib/peraturan.
								</li>
							</ul>
						</>
					)
				},
				{
					title: "Kreativitas",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>
									Mampu menciptakan hasil karya yang baru dalam bidang sains.
								</li>
								<li>Mampu berkreasi dalam bidang seni dan olahraga.</li>
								<li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
								<li>
									Selalu disiplin dalam melaksanakan tata-tertib/peraturan.
								</li>
							</ul>
						</>
					)
				},
				{
					title: "Inovatif",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>Mampu menciptakan hasil karya ilmiah.</li>
								<li>Mampu mengembangkan gagasan/ide yang baru</li>
								<li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
								<li>Tangguh dalam menyelesaikan masalah.</li>
							</ul>
						</>
					)
				}
			]
		},
		extracurricular: {
			title: "Ekstrakulikuler",
			description:
				"Kegiatan Sekolah SMA Sunda Kelapa terdapat berbagai cabang ekskul yang ditawarkan yang meliputi:",
			paragraphs: [
				{
					name: "Basket"
				},
				{
					name: "Voli"
				},
				{
					name: "Badminton"
				},
				{
					name: "Tari"
				},
				{
					name: "Paskibra"
				},
				{
					name: "Pramuka"
				},
				{
					name: "Futsal"
				},
				{
					name: "Karate"
				},

			]
		}
	},
	GalleryPage: {
		title: "Galeri Sekolah",
		page: "Halaman",
		outOf: "dari"
	},
	TeacherPage: {
		teacher: {
			title: "Tenaga Pendidik",
			details: [
				{
					name: 'Ir. Aminah',
					position: 'Kepala Sekolah',
					image: irAminah,
					highlighted: true,
					tier: 1,
				},
				{
					name: 'Maretha Murti Kundari, S.H.',
					position: 'Wakil Kepala Sekolah / Guru Geografi',
					image: maretha,
					highlighted: true,
					tier: 2,
				},
				{
					name: 'Suswati, M.Pd',
					position: 'Koordinator Kurikulum / Guru Matematika',
					image: suswati,
					highlighted: true,
					tier: 2,
				},
				{
					name: 'Iin Chintya Asmara, M.Pd',
					position: 'Guru Matematika',
					image: lin,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Yudi Rianto, S.Pd',
					position: 'Guru Sosiologi / Pembina OSIS',
					image: yudi,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Andi Yuniawan, B.A',
					position: 'Guru Bahasa Inggris',
					image: andi,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Arrofik Showi, S.Ag',
					position: 'Guru Pendidikan Agama Islam',
					image: arofik,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Sutrimo, S.Pd',
					position: 'Guru Bahasa Indonesia',
					image: sutrimo,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Muarip, S.Pd',
					position: 'Guru Sejarah',
					image: muarip,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Bimasyah Sihite, S.Pd',
					position: 'Guru Sejarah',
					image: bimansyah,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Dianita Wulansari, S.Pd',
					position: 'Guru BK',
					image: dianita,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Dra. Sri Aminah',
					position: 'Guru Ekonomi',
					image: sri_aminah,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Dody Riswanto, M.Pd',
					position: 'Guru BK',
					image: doddy,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Muharni Afridita, S.Pd',
					position: 'Guru Fisika',
					image: maharni,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Dita Mulyawati, S.Pd',
					position: 'Guru Biologi',
					image: dita,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Hairunnisyah, S.Pd',
					position: 'Guru Kimia',
					image: hairunnisyah,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Yoto, S.E',
					position: 'Guru PKWU - Guru Informatika',
					image: yoto,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Rita Kusuma Dewi, S.Pd',
					position: 'Guru Bahasa Perancis',
					image: rita,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Chairil, S.H',
					position: 'Guru PKN',
					image: chairil,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Ishadi, S.E',
					position: 'Kepala TU',
					image: ishadi,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Eko Prasetyo',
					position: 'TU - Operator bagian IT',
					image: eko,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Kusyadi',
					position: 'TU',
					image: kusyadi,
					highlighted: false,
					tier: 3,
				},
			]
		},
		management: {
			title: "Anggota Yayasan",
			details: [
				{
					position: "Ketua Yayasan"
				},
				{
					position: "Wakil Ketua"
				},
				{
					position: "Pengawas"
				},
				{
					position: "Pembina"
				}
			]
		}
	},
	NewAdmissionPage: {
		downloadBrocureTitle: 'Unduh Brosur',
		section1: {
			title: "Penerimaan Peserta Didik Baru (PPDB)",
			description: (
				<>
					Sistem seleksi penerimaan peserta didik baru di SMA Sunda Kelapa dilakukan melalui proses pendaftaran secara langsung atau luring. Calon peserta didik datang ke sekolah untuk mendaftarkan diri sebagai peserta seleksi penerimaan siswa baru. Proses pendaftaran dilakukan dengan mendatangi lokasi SMA Sunda Kelapa sesuai jadwal yang telah ditentukan oleh pihak sekolah.
				</>
			)
		},
		section2: {
			title: "Proses Pendaftaran",
			description1:
				"",
			description2:
				(
					<>
						Dapat melakukan pendaftaran secara <strong>luring</strong>, yaitu datang langsung ke <strong>SMA Sunda Kelapa</strong>
					</>
				),
			description3:
				"Syarat pendaftaran sebagai berikut :",
			description4:
				"Informasi Pendaftaran dapat menghubungi Melalui : (021) 3902839 (Call Center)",
			description5: 'Timeline pendaftaran : Januari - Juli',
			requirements: [
				"Formulir Pendaftaran",
				`Foto Copy SKHUN/IJazah SMP`,
				`Pas Photo 3x4 = 4 Lembar`,
				`Pas Photo 2x3 = 2 Lembar`,
				`FotoCopy Kartu Keluarga`,
				`FotoCopy Raport SMP`
			]
		},
		section4: {
			title: "Tahapan",
			description: "Tahapan PSB Jalur",
			admissionTypes: {
				achievement: {
					title: "PSB Jalur Prestasi",
					short: "Prestasi",
					timeline: [
						{
							title: "Tahap 1",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 2",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 3",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 4",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 5",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 6",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						}
					]
				},
				test: {
					title: "PSB Jalur Tes Ujian",
					short: "Tes Ujian",
					timeline: [
						{
							title: "Tahap 1",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 2",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 3",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 4",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 5",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 6",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						}
					]
				}
			}
		}
	},
	faqPage: {
		title: 'FAQ about Sunda Kelapa High School',
		qna: [
			{
				question: "Bagaimana proses pendaftaran siswa baru di SMA ini?",
				answer:
					"Secara langsung (luring) dengan mendatangi SMA Sunda Kelapa dan syarat bagi calon siswa yaitu mengikuti persyaratan akademik            "
			},
			{
				question:
					"Berapa biaya yang harus dikeluarkan untuk mendaftar di SMA ini?",
				answer:
					"Untuk biaya SPP yaitu Rp600.000 dan untuk biaya uang pangkal yaitu sekitar Rp6.000.000"
			},
			{
				question: "Apa jadwal akademik dan libur tahun ini?",
				answer: "Mengikuti kalender akademik yang sudah ditetapkan"
			},
			{
				question:
					"Bagaimana saya bisa berpartisipasi dalam klub dan kegiatan ekstrakurikuler?",
				answer:
					"Terdapat proses rekrutmen melalui talent scouting yang akan disesuaikan dengan minat peserta didik            "
			},
			{
				question: "Apa fasilitas yang tersedia di SMA ini?",
				answer: (
					<ol>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Kantin</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Lab . komputer</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Lab. IPA</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Perpustakaan</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Ruang BK</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Ruang OSIS</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>UKS</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Masjid</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Musholla</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Ruangan kelas ber-AC</span>
								</span>
							</span>
						</li>
					</ol>
				)
			},
			{
				question: "Apa kurikulum yang digunakan?",
				answer: "Kurikulum yang digunakan yaitu Kurikulum Merdeka"
			},
			{
				question: "Bagaimana cara menghubungi staf sekolah?",
				answer: (
					<>
						<p>Untuk umum dapat menghubungi nomor (021) 390 2839</p>
						<p>Untuk wali murid dapat menghubungi melalui Whatsapp Group</p>
					</>
				)
			},
			{
				question: "Apa yang membuat sekolah ini unik?",
				answer: (
					<>
						<ul>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									<strong>Lokasi yang strategis dan berada di pusat Jakarta</strong>
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									{" "}
									Wilayah sekolah yang bebas tawuran
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>Akses mudah</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									Terdapat kegiatan Baca Tulis Al-Qur’an (BTQ).&nbsp;
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									<strong>Salah satu Sekolah Swasta tertua di Jakarta yang sudah berdiri
										selama 59 tahun&nbsp;</strong>
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									Bangunan dari SMA Sunda Kelapa sudah ditetapkan menjadi cagar
									budaya
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									Sekolah ini berdampingan dengan Masjid Agung Sunda Kelapa
								</span>
							</li>
						</ul>
					</>
				)
			},
			{
				question:
					"Bagaimana saya bisa mendapatkan informasi terbaru tentang kegiatan dan acara sekolah?",
				answer: (
					<>
						<p>Melalui 3 platform yang sudah disediakan, yaitu:</p>
						<ul>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>Instagram</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>Website</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>Call Center</span>
							</li>
						</ul>
					</>
				)
			}
		]
	},
	HomePage: {
		welcome: {
			title: (
				<>
					Selamat Datang di
					<br />
					SMA Sunda Kelapa Menteng
				</>
			),
			description:
				"SMA Sunda Kelapa merupakan salah satu sekolah swasta tertua yang berlokasi di Jakarta Pusat. Didirikan oleh tokoh pendidikan Ny. Hj. Sjamsidar Moerdono, SMA Sunda Kelapa berdiri sejak tahun 1963 dengan tujuan untuk menempa peserta didik menjadi individu yang unggul dan berprestasi serta mampu bersaing di era global.",
			button: "Penerimaan Peserta Didik Baru (PPDB)"
		},
		greetings: {
			title: "Sambutan Kepala Sekolah",
			position: "Kepala sekolah SMA Sunda Kelapa",
			paragraphs_short: (
				<>
					<p>
						Puji dan syukur senantiasa kita panjatkan kehadirat Tuhan Yang Maha
						Esa atas limpahan rahmat dan karunia-Nya, sehingga kami diberikan
						kesempatan untuk menjalankan suatu amanah dalam memimpin SMA SUNDA
						KELAPA .Program kami kedepan ialah ingin menjadikan SMA SUNDA KELAPA
						menjadi salah satu sekolah yang peserta didiknya berwawasan
						lingkungan dan dapat meraih juara dari tingkat kota sampai tingkat
						Nasional.
						</p>
				</>
			),
			paragraph: (
				<>
					<p>
						Puji dan syukur senantiasa kita panjatkan kehadirat Tuhan Yang Maha
						Esa atas limpahan rahmat dan karunia-Nya, sehingga kami diberikan
						kesempatan untuk menjalankan suatu amanah dalam memimpin SMA SUNDA
						KELAPA .Program kami kedepan ialah ingin menjadikan SMA SUNDA KELAPA
						menjadi salah satu sekolah yang peserta didiknya berwawasan
						lingkungan dan dapat meraih juara dari tingkat kota sampai tingkat
						Nasional. Tentunya itu bukanlah suatu hal yang mudah namun dengan
						kemauan yang kuat dan kerjasama yang baik dari semua warga sekolah
						kami yakin hal tersebut dapat diraih. Semoga Allah meridhoi dan
						memberikan kemudahan dalam meraihnya. Website SMA SUNDA KELAPA telah
						menjadi salah satu pilihan untuk masyarakat dalam mencari tahu
						profil, informasi dan kegiatan di SMA SUNDA KELAPA. Untuk itu, sudah
						selayaknya lembaga pendidikan memiliki website sebagai sarana
						komunikasi antara guru, peserta didik dan orang tua. Di samping itu,
						Website sekolah juga bisa menjadi pusat informasi bagi pengunjung
						dan mereka yang ingin melanjutkan jenjang pendidikannya lebih
						tinggi.
					</p>
					<p>Kepala Sekolah</p>
					<p>Ir. Aminah</p>
				</>
			)
		},
		about: {
			title: "Tentang Sekolah",
			description:
				"SMA Sunda Kelapa, didirikan tahun 1963, adalah sekolah swasta tertua yang berkomitmen dalam meningkatkan kualitas pendidikan Indonesia. Mengusung visi Unggul dalam Prestasi dan Santun dalam Perilaku, sekolah ini menanamkan nilai-nilai utama beriman, percaya diri, rendah hati, kreatif dan inovatif guna membentuk generasi tangguh menyongsong masa depan.",
			more: "Selengkapnya",
			less : "Sembunyikan"
		},
		facilities: {
			title: "Fasilitas",
			list: [
				{
					name: "Kantin",
					icon: 'material-symbols:store',
				},
				{
					name: "Laboratorium Komputer",
					icon: 'ri:computer-fill',
				},
				{
					name: "Laboratorium IPA",
					icon: 'medical-icon:i-laboratory',
				},
				{
					name: "Perpustakaan",
					icon: 'mdi:library',
				},
				{
					name: "Ruang Bimbingan Konseling",
					icon: 'raphael:bubble',
				},
				{
					name: "Ruang OSIS",
					icon: 'mingcute:microphone-fill',
				},
				{
					name: "Ruang UKS",
					icon: 'mdi:medical-bag',
				},
				{
					name: "Musholla",
					icon: `fluent:building-mosque-24-filled`,
				},
				{
					name: "Ruangan Kelas Ber-AC",
					icon: `mdi:air-conditioner`,
				}
			]
		},
		activities: {
			title: "Kegiatan Sekolah",
			description:
				"Kegiatan Sekolah SMA Sunda Kelapa terdapat berbagai kegiatan yang ditawarkan yang meliputi:",
			list: [
				{
					name: "Basket",
					description: "Klub basket untuk mengasah skill, kerja sama tim dan menjaga kebugaran siswa."
				},
				{
					name: "Karyawisata",
					description: "Kegiatan jelajah dan kunjungan ke berbagai tempat untuk memperluas wawasan dan pengalaman siswa."
				},
				{
					name: "LDKS",
					description: "Latihan Dasar Kepemimpinan Siswa, wadah untuk mengasah skill kepemimpinan siswa melalui outbond dan kerja tim."
				},
				{
					name: "Laboratorium",
					description: "SMA Sunda Kelapa menyediakan 2 laboratorium yaitu laboratorium IPA dan laboratorium komputer yang digunakan untuk mendukung kegiatan pembelajaran siswa. Kedua laboratorium ini dilengkapi dengan peralatan dan fasilitas yang memadai guna membantu siswa melakukan praktikum serta meningkatkan keterampilan dalam bidang sains dan teknologi informasi. Keberadaan laboratorium yang representatif ini semakin memperkuat proses belajar mengajar di SMA Sunda Kelapa.					"
				},
				{
					name: "Osis",
					description: "OSIS SMA Sunda Kelapa membuka peluang siswa untuk kembangkan kepemimpinan dan kreativitas. Dengan pemilihan setiap tahun, OSIS bertanggung jawab atas kegiatan, seperti lomba dan perayaan. Ini efektif membangun keterampilan kepemimpinan dan interaksi sosial siswa."
				},
				{
					name: "Paskibra",
					description: "Pasukan Pengibar Bendera untuk menumbuhkan nasionalisme dan kedisiplinan siswa."
				},
				{
					name: "Sholat Berjamaah",
					description: "Sholat berjamaah khususnya pada saat Sholat Zuhur dilaksanakan secara rutin di Masjid Agung Sunda Kelapa yang terletak bersebelahan dengan SMA Sunda Kelapa. Kegiatan ini merupakan upaya sekolah dalam menanamkan nilai-nilai religiusitas dan disiplin pada siswa. Masjid Agung Sunda Kelapa menjadi pusat ibadah dan pengembangan spiritual bagi warga SMA Sunda Kelapa."
				},
				{
					name: "Tadarus al-Qur'an",
					description: "Tadarus al-Quran merupakan rutinitas  yang dilaksanakan oleh para peserta didik SMA Sunda Kelapa. Kegiatan membaca dan mengkaji al-Quran ini bertujuan untuk meningkatkan pemahaman dan penghayatan terhadap isi kandungan kitab suci umat Islam. Melalui tadarus al-Quran, diharapkan dapat menumbuhkembangkan akhlak dan budi pekerti luhur pada generasi muda."
				},
				{
					name: "Voli",
					description: "Klub bola voli yang melatih siswa bekerja sama, disiplin, dan menjunjung sportivitas. "
				},
				{
					name: "Badminton",
					description: "Wahana meningkatkan kecepatan berpikir dan ketangkasan siswa melalui olahraga badminton. "
				},
				{
					name: "Tari",
					description: "Ekstrakurikuler mengekspresikan seni tari untuk melatih kreativitas dan apresiasi budaya siswa"
				},
				{
					name: "Pramuka",
					description: "Wadah mengasah kecakapan hidup dan kepemimpinan siswa melalui kegiatan kepramukaan."
				},
				{
					name: "Futsal",
					description: "Olahraga futsal yang melatih kerja sama, strategi dan kebugaran siswa."
				},
				{
					name: "Karate",
					description: "Beladiri untuk melatih kedisiplinan, fokus dan pembelaan diri siswa."
				}

			]
		},
		facts: {
			students: "Peserta didik",
			teachers: "Tenaga Kependidikan",
			admitted: "Lolos PTN"
		},
		testimonial: {
			title: "Testimoni",
			list: [
				{
					message:
						"Putri saya menjadi lebih percaya diri dan aktif berorganisasi sejak masuk SMA Sunda Kelapa. Berkat didikan guru-guru yang sabar dan pendekatan yang menyeluruh dalam membina siswa, putri saya yang dulunya pemalu kini berani tampil di depan umum dan terpilih menjadi bendahara OSIS. Kami bangga dengan perkembangannya Berkat lingkungan dan pendampingan yang tepat dari pihak sekolah.",
					position: "Rahma (orangtua siswa)"
				},
				{
					message:
						"Sebagai orangtua, saya merasa sangat tepat memilihkan SMA Sunda Kelapa sebagai sekolah putra sulung saya. Di sini dia tidak hanya mendapat pendidikan akademik yang kuat, tapi juga pendidikan karakter dan kepemimpinan melalui kegiatan ekstrakurikuler Pramuka. Putra saya kini lebih mandiri, disiplin dan memiliki keterampilan organisasi yang baik berkat didikan sekolah ini. Saya yakin dia siap meraih cita-citanya.",
					position: "Bapak Budi (orangtua siswa)"
				},
			]
		}
	},

	OsisPage: {
		title: 'OSIS',
		description: 'OSIS (Organisasi Siswa Intra Sekolah) SMA Sunda Kelapa memberikan kesempatan kepada para siswa untuk mengasah keterampilan kepemimpinan dan kreativitas mereka. Pengurus OSIS dipilih melalui pemilihan yang diadakan setahun sekali. Hal ini merupakan salah satu cara untuk memperkenalkan demokrasi di kalangan siswa. Selain itu, sekolah juga mempercayakan OSIS untuk menyelenggarakan acara dan kegiatan seperti lomba, perayaan Hari Besar Nasional, dan juga kegiatan amal. OSIS adalah organisasi yang benar-benar mampu membangun keterampilan kepemimpinan, kemandirian, dan sosialisasi para siswa.',
		socialMediaTitle: 'Media Sosial',
		periodTitle: '3 Periode Terakhir',
		list: [
			{
				periodImage: osisImage1,
				periodTitle: 'OSIS Periode 2023-2024',
				periodDescription: 'Emiestungkoro Cadudasa',

			},
			{
				periodImage: osisImage2,
				periodTitle: 'OSIS Periode 2022-2023',
				periodDescription: '[Nama Kabinet Pengurus Osis]',

			},
			{
				periodImage: osisImage3,
				periodTitle: 'OSIS Periode 2021-2022',
				periodDescription: 'Emiestungkoro Cadudasa',

			}

		]
	},
	AchievementPage: {
		title: 'Prestasi Siswa',
		description: 'SMA Sunda Kelapa berhasil menempatkan <strong>17 lulusannya</strong> di <strong>11 Perguruan Tinggi Negeri (PTN)</strong> yang tersebar di seluruh Indonesia pada tahun ini. Pencapaian ini menunjukkan kualitas pendidikan serta prestasi akademik dan non-akademik yang dimiliki para siswa SMA Sunda Kelapa.',
		subDescription: 'Peserta didik yang berhasil masuk PTN, antara lain:',
		list: [
			{
				id: 1,
				name: 'Andika Hartawan',
				ptn_name: 'Universitas Brawijaya',
				image: andika_acv
			},
			{
				id: 2,
				name: 'Salsabila',
				ptn_name: 'UDAYANA',
				image: salsabila_acv
			},
			{
				id: 3,
				name: 'Gustiara Widia Utari',
				ptn_name: 'UPN Veteran Jakarta',
				image: gustiara_acv
			},
			{
				id: 4,
				name: 'Allysandra',
				ptn_name: 'Universitas Negeri Jakarta',
				image: null
			},
			{
				id: 5,
				name: 'M. Ibrahim',
				ptn_name: 'Universitas Haluoleo',
				image: ibrahim_acv
			},
			{
				id: 6,
				name: 'Siti Zahra',
				ptn_name: 'Universitas Negeri Semarang',
				image: siti_zahra_acv,
			},
			{
				id: 7,
				name: 'M. Nail Khalifah',
				ptn_name: 'Universitas Negeri Jakarta',
				image: nail_acv
			},
			{
				id: 8,
				name: 'Anggi Fatma',
				ptn_name: 'UDAYANA',
				image: null
			},
			{
				id: 9,
				name: 'Indah Widanti',
				ptn_name: 'Universitas Negeri Makassar',
				image: null
			},
			{
				id: 10,
				name: 'Devira Julia Komara',
				ptn_name: 'UDAYANA',
				image: devira_acv
			},
			{
				id: 11,
				name: 'M. Raffa',
				ptn_name: 'Universitas Negeri Jakarta',
				image: raffa_acv
			},
			{
				id: 12,
				name: 'Genggam Yoga Hartomo',
				ptn_name: 'Universitas Brawijaya',
				image: genggam_acv
			},
			{
				id: 13,
				name: 'Rakha Catra',
				ptn_name: 'Universitas Negeri Jakarta',
				image: rakha_acv
			},
			{
				id: 14,
				name: 'Syahrani',
				ptn_name: 'Universitas Diponegoro',
				image: syahrini_acv
			},
			{
				id: 15,
				name: 'Dian Alivia',
				ptn_name: 'Universitas Indonesia'
			},
			{
				id: 16,
				name: 'M. Maulana Yusuf',
				ptn_name: 'Universitas Lampung',
				image: maulana_acv
			},
			{
				id: 17,
				name: 'Marchela Calista',
				ptn_name: 'Universitas Negeri Jakarta',
				image: marchela_acv

			},

		]
	},
	footerInfo: {
		address: 'Jl. Taman Sunda Kelapa No 16. A Menteng, Jakarta Pusat',
		footerTitle: 'SMA Sunda Kelapa Menteng'
	},
	links: {
		home: "Beranda",
		teachers: "Tenaga Pendidik",
		newStudents: {
			long: "Penerimaan Peserta Didik Baru (PPDB)",
			short: "PPDB"
		},
		faq: "FAQ",
		about: "Tentang Sekolah",
		history: "Sejarah",
		generalInfo: "Informasi Umum",
		visionmission: "Visi dan Misi",
		coreValue: "Core Value",
		gallery: "Galeri Sekolah",
		shortLink: "Pintasan Link"
	}
}
