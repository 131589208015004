import Activity from "../models/Activity"
import noImage from "../../assets/noimage.png"
import CustomHeading from "../reusable/CustomHeading"
import {
    Col,
    Container,
    Image,
    Row
} from "react-bootstrap"
import { Link, useNavigate, useOutletContext } from "react-router-dom"

export default function ActivityPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    return (
        <>
            <Container style={{ paddingTop: isMobile ? '1rem' : '8rem', paddingBottom: isMobile ? '1rem' : '0' }}>
                <Row className="mt-4 mb-5">
                    <Col
                        className={
                            "d-flex flex-column align-items-center justify-content-center"
                        }
                        md={12}
                    >
                        <CustomHeading text={lang.HomePage.activities.title} />
                        <p>
                            {lang.HomePage.activities.description}
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1">
                    {Activity.map((v, index) => {
                        return (
                            <>
                                <Col className="mb-4" md={3} lg={2} xl={2} sm={6} xs={6}>
                                    <div class="img-activity">
                                        <Image
                                            style={{
                                                minHeight: isMobile ? 150 : 250,
                                                objectFit: "cover"
                                            }}
                                            className="img-responsive img-fluid"
                                            src={v.image}
                                        ></Image>
                                        <div class="overlay">
                                            <div
                                                className="caption-title"
                                                style={{
                                                    color: "#FFF",
                                                    fontWeight: 800,
                                                    textAlign: "center",
                                                    paddingTop: 20,
                                                    paddingLeft:3,
                                                    paddingRight:3,
                                                    marginTop: "auto",
                                                    marginBottom: "auto"
                                                }}
                                            >
                                                <div className="mb-3"
                                                style={isMobile ? {position:'relative', top:-10} : {}}
                                                >
                                                    {lang.HomePage.activities.list[index].name}
                                                </div>

                                                <div
                                                    className="pb-1"
                                                    style={{ fontSize: '0.7em', background: 'transparent !important' }}
                                                >
                                                    {lang.HomePage.activities.list[index].description}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </>
                        )
                    })}
                </Row>
            </Container>
        </>
    )
}