import irAminah from '../../assets/teacher/Ir. Aminah.jpg'
import maretha from '../../assets/teacher/Maretha Murti Kundari, S.H.jpg'
import suswati from '../../assets/teacher/Suswati, M.Pd.jpg'
import lin from '../../assets/teacher/Iin Chintya Asmara, M.Pd.jpg'
import yudi from '../../assets/teacher/Yudi Rianto, S.Pd.jpg'
import andi from '../../assets/teacher/Andi Yuniawan, B.A.jpg'
import arofik from '../../assets/teacher/Arrofik Showi, S.Ag.jpg'
import sutrimo from '../../assets/teacher/Sutrimo, S.Pd.jpg'
import muarip from '../../assets/teacher/Muarip, S.Pd.jpg'
import bimansyah from '../../assets/teacher/Bimasyah Sihite, S.Pd.jpg'
import dianita from '../../assets/teacher/Dianita Wulansari, S.Pd.jpg'
import sri_aminah from '../../assets/teacher/Dra. Sri Aminah.jpg'
import doddy from '../../assets/teacher/Dody Riswanto, M.Pd.jpg'
import maharni from '../../assets/teacher/Muharni Afridita, S.Pd.jpg'
import dita from '../../assets/teacher/Dita Mulyawati, S.Pd.jpg'
import hairunnisyah from '../../assets/teacher/Hairunnisyah, S.Pd.jpg'
import yoto from '../../assets/teacher/Yoto, S.E.jpg'
import rita from '../../assets/teacher/Rita Kusuma Dewi, S.Pd.jpg'
import chairil from '../../assets/teacher/ChairiSH.jpg'
import ishadi from '../../assets/teacher/Ishadi, S.E.jpg'
import eko from '../../assets/teacher/Eko Prasetyo.jpg'
import kusyadi from '../../assets/teacher/Kusyadi.jpg'
import noImage from '../../assets/noimage.png'

const teachers = [
    {
        name: 'Ir. Aminah',
        position: 'Kepala Sekolah',
        image: irAminah,
        highlighted: true,
        tier: 1,
    },
    {
        name: 'Maretha Murti Kundari, S.H.',
        position: 'Wakil Kepala Sekolah / Guru Geografi',
        image: maretha,
        highlighted: true,
        tier: 2,
    },
    {
        name: 'Suswati, M.Pd',
        position: 'Koordinator Kurikulum / Guru Matematika',
        image: suswati,
        highlighted: true,
        tier: 2,
    },
    {
        name: 'Iin Chintya Asmara, M.Pd',
        position: 'Guru Matematika',
        image: lin,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Yudi Rianto, S.Pd',
        position: 'Guru Sosiologi / Pembina OSIS',
        image: yudi,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Andi Yuniawan, B.A',
        position: 'Guru Bahasa Inggris',
        image: andi,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Arrofik Showi, S.Ag',
        position: 'Guru Pendidikan Agama Islam',
        image: arofik,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Sutrimo, S.Pd',
        position: 'Guru Bahasa Indonesia',
        image: sutrimo,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Muarip, S.Pd',
        position: 'Guru Sejarah',
        image: muarip,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Bimasyah Sihite, S.Pd',
        position: 'Guru Sejarah',
        image: bimansyah,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Dianita Wulansari, S.Pd',
        position: 'Guru BK',
        image: dianita,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Dra. Sri Aminah',
        position: 'Guru Ekonomi',
        image: sri_aminah,
        highlighted: true,
        tier: 3,
    },
    {
        name: 'Dody Riswanto, M.Pd',
        position: 'Guru BK',
        image: doddy,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Muharni Afridita, S.Pd',
        position: 'Guru Fisika',
        image: maharni,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Dita Mulyawati, S.Pd',
        position: 'Guru Biologi',
        image: dita,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Hairunnisyah, S.Pd',
        position: 'Guru Kimia',
        image: hairunnisyah,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Yoto, S.E',
        position: 'Guru PKWU - Guru Informatika',
        image: yoto,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Rita Kusuma Dewi, S.Pd',
        position: 'Guru Bahasa Perancis',
        image: rita,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Chairil, S.H',
        position: 'Guru PKN',
        image: chairil,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Ishadi, S.E',
        position: 'Kepala TU',
        image: ishadi,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Eko Prasetyo',
        position: 'TU - Operator bagian IT',
        image: eko,
        highlighted: false,
        tier: 3,
    },
    {
        name: 'Kusyadi',
        position: 'TU',
        image: kusyadi,
        highlighted: false,
        tier: 3,
    },
]

const foundations = [
    {
        name: 'Head 1',
        position: 'Ketua Yayasan',
        image: noImage,
        highlighted: true,
        tier: 1,
    },
    {
        name: 'Head 2',
        position: 'Wakil Ketua',
        image: noImage,
        highlighted: true,
        tier: 2,
    },
    {
        name: 'Head 2',
        position: 'Pengawas',
        image: noImage,
        highlighted: true,
        tier: 2,
    },
    {
        name: 'Head 2',
        position: 'Pembina',
        image: noImage,
        highlighted: true,
        tier: 2,
    },
]

export default { teachers, foundations }