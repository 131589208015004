import React, { useEffect, useState } from 'react';
import Gallery from 'react-image-gallery';
import ReactPaginate from 'react-paginate';
import 'react-image-gallery/styles/css/image-gallery.css';
import galleryImage from "../../assets/gallery.jpg"
import GalleryThumb from '../reusable/GalleryThumb';
import { Button, Container, Row } from 'react-bootstrap';
import CustomHeading from '../reusable/CustomHeading';
import { useOutletContext } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Palette from '../utils/Palette';
import GalleryModel from '../models/Gallery'
import langId from '../../utils/lang/id'
const itemsPerPage = 10; // Number of items per page
const GalleryPageV2 = () => {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    const [currentPage, setCurrentPage] = useState(0);
    const [images, setImages] = useState([])

    const handlePageClick = (data) => {
        const selectedPage = data.selected ? data.selected : data;
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        let res = GalleryModel.map((value) => {
            return value.image
        })
        setImages(res)
    }, [])

    const offset = currentPage * itemsPerPage;
    // const paginatedImages = images?.slice(offset, offset + itemsPerPage);
    const paginatedImages = images?.slice(offset, offset + itemsPerPage);

    return (
        <>
            <Container style={{ padding: isMobile ? '1rem 1rem' : '8rem 0rem' }}>
                <Row className="mb-5">
                    <CustomHeading text={lang.GalleryPage.title} />
                </Row>
                <Row className='justify-content-center'>
                    <GalleryThumb items={paginatedImages} />

                </Row>
                <div className='d-none d-flex w-100 gallery-pagination' style={{ margin: '0 auto', justifyContent: 'center', textAlign: 'center' }}>
                    <ReactPaginate
                        nextLabel={''}
                        previousLabel={''}
                        pageCount={Math.ceil(images.length / itemsPerPage)}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </div>
                <div className='d-flex justify-content-center'>
                    {currentPage >= 1 && <Button onClick={() => {
                        handlePageClick(currentPage - 1)
                    }} variant='link'>
                        <Icon style={{ color: Palette.THEME_GREEN }} icon={'mingcute:left-fill'}></Icon>
                    </Button>}

                    <p className='d-flex my-0' style={{ color: Palette.THEME_GREEN, marginRight: 3, fontWeight: 600, alignItems: 'center' }}>{lang.GalleryPage.page} {currentPage + 1} </p>
                    <p className='d-flex my-0' style={{ color: Palette.THEME_TEXT_DARK, fontWeight: 600, alignItems: 'center' }}> {lang.GalleryPage.outOf} {Math.ceil(images.length / itemsPerPage)}</p>
                    {currentPage < Math.ceil(images.length / itemsPerPage) - 1 && <Button onClick={() => {
                        handlePageClick(currentPage + 1)
                    }} variant='link'>
                        <Icon style={{ color: Palette.THEME_GREEN }} icon={'mingcute:right-fill'}></Icon>
                    </Button>}

                </div>
            </Container>
        </>
    );
};

export default GalleryPageV2;
