export default class Palette {
    static THEME_GREEN = "#0DAE56"
    static THEME_ACTIVE_YELLOW = "#F4FF7B"
    static THEME_INACTIVE = "#FFFFFF"
    static THEME_TEXT_DARK = "#121212"
    static THEME_TEXT_NORMAL = "#444"

    static THEME_BLUE = "#203965"
    static SHADOW = "#20396510"
    static THEME_ACCENT_BLUE = "#36528B"
    static THEME_INACTIVE_GREY = "#999999"
    static THEME_WHITE = "#FFFFFF"
    static THEME_LIGHT_GRAY = '#D9D9D9'
    static THEME_BG_BEIGE = '#e1dad4'
}