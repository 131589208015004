import {
    Col,
    Container,
    Row
} from "react-bootstrap"
import { faker } from '@faker-js/faker';
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useOutletContext } from "react-router-dom"
import CustomHeading from "../reusable/CustomHeading"
import Achievement from "../models/Achievement";
import noImage from "../../assets/noimage.png"
export default function AchievementPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()

    return (
        <>
            <Container
                style={{
                    paddingTop: isMobile ? "1rem" : "8rem",
                    paddingBottom: isMobile ? "1rem" : "1rem",

                }}
            >
                <Row className="mb-5">
                    <CustomHeading text={lang.AchievementPage.title} />
                </Row>

                <p
                    style={
                        isMobile
                            ? { fontSize: "0.9em", lineHeight: 2 }
                            : { fontSize: "1em" }
                    }
                >
                    <div
                        dangerouslySetInnerHTML={{ __html: lang.AchievementPage.description }}
                    />
                </p>

                <p
                    style={
                        isMobile
                            ? { fontSize: "0.9em", lineHeight: 2 }
                            : { fontSize: "1em" }
                    }
                >
                    {lang.AchievementPage.subDescription}
                </p>

            </Container>
            <Container
                className="mb-4"
                style={{ paddingBottom: isMobile ? "1rem" : "1rem", }}
            >
                <Row>
                    {
                        lang.AchievementPage.list.map((v) => {
                            return (
                                <>
                                    <Col md={6} className="mb-4">
                                        <div className="d-flex" style={{ gap: 10 }}>
                                            <div style={{ width: 'auto' }}>
                                                <LazyLoadImage
                                                    effect="blur"
                                                    // src={faker.image.avatarLegacy(50, 50, true)}
                                                    src={v.image ? v.image : noImage}
                                                    width={100}
                                                    height={130}

                                                />
                                            </div>
                                            <div className={'my-auto'}>
                                                <CustomHeading fontSize={'1em'} text={v.name} />
                                                <p>{v.ptn_name}</p>
                                            </div>
                                        </div>

                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
            </Container >
        </>
    )
}