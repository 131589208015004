export default [
    {
        name: 'Kantin',
        icon: 'material-symbols:store',
    },
    {
        name: 'Laboratorium Komputer',
        icon: 'ri:computer-fill',
    },
    {
        name: 'Laboratorium IPA',
        icon: 'medical-icon:i-laboratory',
    },
    {
        name: 'Perpustakaan',
        icon: 'mdi:library',
    },
    {
        name: 'Ruang Bimbingan Konseling',
        icon: 'raphael:bubble',
    },
    {
        name: 'Ruang OSIS',
        icon: 'mingcute:microphone-fill',
    },
    {
        name: 'Ruang UKS',
        icon: 'mdi:medical-bag',
    },
    {
        name: 'Musholla',
        icon: `fluent:building-mosque-24-filled`,
    },
    {
        name: 'Ruangan Kelas Ber-AC',
        icon: `mdi:air-conditioner`,
    },

]