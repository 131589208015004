
import Extrakulikuler from "../models/Extrakulikuler";
import noImage from "../../assets/noimage.png"
import { Col, Container, Image, Row } from "react-bootstrap";
import CustomHeading from "../reusable/CustomHeading";
import { useOutletContext } from "react-router-dom";
export default function Ekstrakulikuler() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext();
    return (
        <>
            <Container style={{ paddingTop: isMobile ? '1rem' : '8rem', paddingBottom: isMobile ? '1rem' : '0' }}>
                <Row className="mb-5">
                    <CustomHeading text={"Ekstrakulikuler"} />
                </Row>
            </Container>
            <Container className="mb-5">

                <Row className="mt-5">
                    {
                        Extrakulikuler.map((v) => {
                            return (
                                <>
                                    <Col className="mb-4" md={3} lg={3} xl={3} sm={6} xs={6}>
                                        <Image style={{ minHeight: isMobile ? 150 : 350 }} className="img-responsive img-fluid" src={noImage}></Image>
                                        <p style={{ "position": "relative", "bottom": "15%", "left": "5%", "color": "#FFF", "fontWeight": "800" }}>{v.name}</p>
                                    </Col>
                                </>
                            )
                        })
                    }


                </Row>
            </Container>
        </>
    )
}