import Palette from "../utils/Palette"
// import { MENU_HEADER } from "../utils/Menu"
import { Button, Col, Container, Dropdown, Image, Row } from "react-bootstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { Icon } from "@iconify/react"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import logo from "../../assets/logo.png"
import langEn from "../../utils/lang/en"
import langId from "../../utils/lang/id"
import CustomIcon from "../utils/CustomIcon"
export default function Header({ isMobile, lang, setLang, presets }) {
    const [showMenu, setShowMenu] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [expand, setExpand] = useState(false)

    const [scroll, setScroll] = useState(false)
    console.log('isi preset', presets)
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 500)
        })
    }, [])

    const mobileNav = () => {
        return (
            <>
                <Navbar collapseOnSelect style={{ background: Palette.THEME_WHITE, paddingTop: 20 }} key={expand} expand={false}>
                    <Container fluid>
                        <Navbar.Brand style={{
                            color: Palette.THEME_WHITE,
                            fontWeight: 600
                        }} href="/"> <Image style={{ width: 50, marginRight: 10, marginTop: -10 }} src={logo}></Image><b style={{
                            color: Palette.THEME_GREEN, fontSize: presets === 'MOBILE_S' ? 18 :  24, textAlign: 'center'
                        }}>SMA Sunda Kelapa</b></Navbar.Brand>
                        <Navbar.Toggle
                            onClick={() => {
                                setShowMenu(!showMenu)
                            }}
                            style={{ border: "none", color: Palette.THEME_TEXT_DARK }}
                            aria-controls="basic-navbar-nav"
                        >
                            <Icon icon={"heroicons-outline:menu-alt-3"} />
                        </Navbar.Toggle>

                        <Offcanvas placement="end" show={showMenu}>
                            <Offcanvas.Header
                                className="pb-0 pt-0"
                                style={{
                                    background: Palette.THEME_WHITE,
                                    paddingTop: 35,
                                    fontSize: 18,
                                    width: "100%"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        fontWeight: 600,
                                        fontSize: 18,
                                        color: Palette.THEME_GREEN,
                                        alignItems: "center",
                                        verticalAlign: "center"
                                    }}
                                >
                                    <div>
                                        <Button
                                            onClick={() => {
                                                setShowMenu(!showMenu)
                                            }}
                                            style={{ color: Palette.THEME_TEXT_DARK, fontSize: 28 }}
                                            variant="link"
                                        >
                                            <Icon icon={"material-symbols:close"} />
                                        </Button>
                                    </div>
                                </div>
                            </Offcanvas.Header>
                            <Offcanvas.Body style={{ background: Palette.THEME_WHITE }}>
                                <Nav
                                    style={{ lineHeight: 2 }}
                                    className="justify-content-end flex-grow-1 pe-3 border-0"
                                >
                                    {lang.MENU_HEADER.map((obj) => {
                                        return obj.submenu ? (
                                            <>
                                                <NavDropdown
                                                    style={{
                                                        fontWeight: 600,
                                                        color:
                                                            location.pathname === obj.path ||
                                                                obj.submenu
                                                                    .map((v) => v.path)
                                                                    .includes(location.pathname)
                                                                ? Palette.THEME_GREEN
                                                                : Palette.THEME_TEXT_DARK,
                                                        background:
                                                            location.pathname === obj.path ||
                                                                obj.submenu
                                                                    .map((v) => v.path)
                                                                    .includes(location.pathname)
                                                                ? "rgba(13, 174, 86, 0.08)"
                                                                : Palette.THEME_WHITE
                                                    }}
                                                    title={obj.name}
                                                    id="basic-nav-dropdown"
                                                >
                                                    {obj.submenu.map((submenu) => {
                                                        return (
                                                            <NavDropdown.Item
                                                                style={{
                                                                    fontWeight: 600,
                                                                    lineHeight: 1.8,
                                                                    border: "none"
                                                                }}
                                                                href={submenu.path}
                                                            >
                                                                {submenu.name}
                                                            </NavDropdown.Item>
                                                        )
                                                    })}
                                                </NavDropdown>
                                            </>
                                        ) : (
                                            <Nav.Link
                                                style={{
                                                    background:
                                                        location.pathname === obj.path
                                                            ? "rgba(13, 174, 86, 0.08)"
                                                            : Palette.THEME_WHITE,
                                                    color:
                                                        location.pathname === obj.path
                                                            ? Palette.THEME_GREEN
                                                            : Palette.THEME_TEXT_DARK,
                                                    fontWeight: 600
                                                }}
                                                onClick={() => {
                                                    navigate(obj.path)
                                                    setShowMenu(!showMenu)
                                                }}
                                                href="javascript:;"
                                            >
                                                {obj.name}
                                            </Nav.Link>
                                        )
                                    })}
                                    <NavDropdown
                                        className="nav-lang"
                                        id="dropdown-language-full"
                                        title={lang === langId ? renderLangID : renderLangEN}
                                        menuVariant="light"

                                    >
                                        <NavDropdown.Item href="javascript:;" onClick={() => setLang(langId)}>
                                            {renderLangID}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="javascript:;" onClick={() => setLang(langEn)}>
                                            {renderLangEN}
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Container>
                </Navbar>
            </>
        )
    }


    const renderLangID = (
        <>
            <Icon icon={'twemoji:flag-indonesia'} />
            <span style={{ marginLeft: 3 }}>ID</span>
        </>
    )

    const renderLangEN = (
        <>
            <Icon icon={'twemoji:flag-united-kingdom'} />
            <span style={{ marginLeft: 3 }}>EN</span>
        </>
    )

    const normalNav = () => {
        return (
            <>
                <div
                    className={`d-flex flex-row align-items-center justify-content-center ${location.pathname !== "/"
                        ? `sliding-nav`
                        : `${scroll ? "sliding-nav" : "sticky"}`
                        }`}
                >
                    <Container
                        style={{ flexDirection: "column", alignItems: "flex-start" }}
                    >
                        {/* <Row style={{ height: 100 }}> */}
                        <Col
                            className={
                                "d-flex flex-row align-items-center justify-content-center"
                            }
                            xxl={12}
                            style={{ height: 100 }}
                        >
                            <div>
                                <Link className="text-decoration-none text-white" to={"/"}>
                                    <Image
                                        style={{ width: 80, marginRight: 10 }}
                                        src={logo}
                                    ></Image>
                                    <b></b>
                                </Link>
                            </div>
                            <div
                                style={{
                                    flex: 1
                                }}
                            ></div>
                            {!isMobile ? (
                                <>
                                    <div style={{ gap: 25 }} className={`d-flex flex-row`}>
                                        {lang.MENU_HEADER.map((obj) => {
                                            return obj.submenu ? (
                                                <Dropdown>
                                                    {!scroll && location.pathname == "/" ? (
                                                        <Dropdown.Toggle
                                                            className="py-0"
                                                            style={{
                                                                fontWeight: 600,
                                                                textDecoration: "none",
                                                                color:
                                                                    location.pathname === obj.path
                                                                        ? Palette.THEME_ACTIVE_YELLOW
                                                                        : Palette.THEME_INACTIVE
                                                            }}
                                                            variant="link"
                                                            id="dropdown-basic"
                                                        >
                                                            {obj.name}
                                                        </Dropdown.Toggle>
                                                    ) : (
                                                        <Dropdown.Toggle
                                                            className="py-0"
                                                            style={{
                                                                fontWeight: 600,
                                                                color:
                                                                    location.pathname === obj.path ||
                                                                        obj.submenu
                                                                            .map((v) => v.path)
                                                                            .includes(location.pathname)
                                                                        ? Palette.THEME_GREEN
                                                                        : Palette.THEME_TEXT_DARK,
                                                                cursor: "pointer",
                                                                textDecoration: "none"
                                                            }}
                                                            variant="link"
                                                            id="dropdown-basic"
                                                        >
                                                            {obj.name}
                                                        </Dropdown.Toggle>
                                                    )}

                                                    <Dropdown.Menu
                                                        style={{
                                                            borderRadius: 0,
                                                            border: 0,
                                                            boxShadow:
                                                                " 0px 4px 40px 0px rgba(18, 18, 18, 0.08)"
                                                        }}
                                                    >
                                                        {obj.submenu.map((sub) => {
                                                            return (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            navigate(sub.path)
                                                                        }}
                                                                        className={`dropdown-big-custom`}
                                                                        style={{ lineHeight: 2.3, fontWeight: 600 }}
                                                                    >
                                                                        {sub.name}
                                                                    </Dropdown.Item>
                                                                </>
                                                            )
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) : (
                                                <div
                                                    className={"ms-3 me-3"}
                                                    style={
                                                        !scroll && location.pathname == "/"
                                                            ? {
                                                                fontWeight: 600,
                                                                color:
                                                                    location.pathname === obj.path
                                                                        ? Palette.THEME_ACTIVE_YELLOW
                                                                        : Palette.THEME_INACTIVE,
                                                                cursor: "pointer"
                                                            }
                                                            : {
                                                                fontWeight: 600,
                                                                color:
                                                                    location.pathname === obj.path
                                                                        ? Palette.THEME_GREEN
                                                                        : Palette.THEME_TEXT_DARK,
                                                                cursor: "pointer"
                                                            }
                                                    }
                                                    onClick={() => navigate(obj.path)}
                                                >
                                                    {obj.name}
                                                </div>
                                            )
                                        })}
                                        <NavDropdown
                                            className="nav-lang"
                                            id="dropdown-language-full"
                                            title={lang === langId ? renderLangID : renderLangEN}
                                            menuVariant="light"

                                        >
                                            <NavDropdown.Item href="javascript:;" onClick={() => setLang(langId)}>
                                                {renderLangID}
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="javascript:;" onClick={() => setLang(langEn)}>
                                                {renderLangEN}
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                        {/* {lang === langId ?
                                            <div
                                                className={"ms-3 me-3"}
                                                onClick={() => setLang(langEn)}
                                            >
                                                EN
                                            </div> :
                                            <div
                                                className={"ms-3 me-3"}
                                                onClick={() => setLang(langId)}
                                            >
                                                ID
                                            </div>
                                        } */}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Icon
                                        icon={"heroicons-outline:menu-alt-3"}
                                        onClick={() => setShowMenu(!showMenu)}
                                    />
                                </>
                            )}
                        </Col>
                        {/*  </Row> */}
                    </Container>
                </div >
            </>
        )
    }

    const handleRender = () => {
        return isMobile ? mobileNav() : normalNav()
    }

    return handleRender()
}
