import Palette from "../utils/Palette";
import { MENU_FOOTER } from "../utils/Menu";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from '../../assets/logo.png'
import CustomHeading from "../reusable/CustomHeading"

export default function Footer({ isMobile, lang }) {
	const navigate = useNavigate()
	const location = useLocation()
	return <div className={"d-flex flex-row align-items-center justify-content-center"} style={{
		color: "white",
		background: Palette.THEME_GREEN,
		width: "100vw",
		paddingTop: 50,
		paddingBottom: 50
	}}>
		<Container>
			<Row>
				<Col className={"d-flex flex-row align-items-start justify-content-start " + isMobile ? "mb-1" : ""} md={6}>
					<Link className="text-white text-decoration-none" to={'/'}>
						<div className="d-flex">
							<Image style={{ width: 50, marginRight: 10 }} src={logo}></Image>
							<h3 style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold' }}>{lang.footerInfo.footerTitle}</h3>
						</div>
					</Link>
					<div className={"d-flex flex-column"}>
						<div className={"mt-4 mb-2"}>
							{lang.footerInfo.address}<br></br><br></br>
						</div>
						<div style={{ flexDirection: 'column', rowGap: 10 }} className="d-flex">
							<div>
								<Icon icon="ic:baseline-email" /><b> e-mail</b> <br></br>
								<Link target={'_blank'} className="text-white text-decoration-none" to={'mailto:smasundakelapa.eska@gmail.com'}>
									smasundakelapa.eska@gmail.com
								</Link><br></br>
								<Link target={'_blank'} className="text-white text-decoration-none" to={'mailto:unbksundakelapa@gmail.com '}>
									unbksundakelapa@gmail.com
								</Link>
							</div>

							<div>
								<Icon icon="ic:baseline-phone" /><b> Call Center </b><br></br>
								<Link className="text-white text-decoration-none">
									(021) 390 2839
								</Link>
							</div>
							<div>
								<Icon icon="ant-design:instagram-filled" /><b>Instagram</b> <br></br>
								<Link target={'_blank'} className="text-white text-decoration-none" to={'https://www.instagram.com/myschool.sk/'}>
									@myschool.sk
								</Link>
							</div>
						</div>


					</div>
				</Col>
				<Col style={{ marginLeft: 'auto', marginRight: 'auto' }} className={"d-flex flex-column" + isMobile ? "mb-4" : ""} md={3}>
					<h3 style={{ fontWeight: 'bold' }} className={"mb-4"}>{lang.links.shortLink}</h3>
					<Row
						className={"mt-1 mb-1"}
						style={{
							color: Palette.THEME_WHITE
						}}
					>
						{
							lang.MENU_FOOTER?.filter((v) => {
								return v.group === 1
							}).map((obj => {
								return (
									<Col className="mb-2 mt-2 ml-auto" md={12}>
										<Link style={{
											fontWeight: 600,
											textDecoration: 'none',
											color: Palette.THEME_WHITE
										}} to={obj.path}>{obj.name}</Link>
									</Col>
								)


							}))
						}

					</Row>

				</Col>
				<Col style={{ marginLeft: 'auto', marginRight: 'auto' }} className={"d-flex flex-column" + isMobile ? "mb-4" : ""} md={3}>
					<h3 style={{ fontWeight: 'bold' }} className={"mb-4"}>	&nbsp;</h3>
					<Row
						className={"mt-1 mb-1"}
						style={{
							color: Palette.THEME_WHITE
						}}
					>
						{
							lang.MENU_FOOTER?.filter((v) => {
								return v.group === 2
							}).map((obj) => {
								return (
									<Col className="mb-2 mt-2 ml-auto" md={12}>
										<Link style={{
											marginLeft: !obj.isTitle ? 10 : 0,
											fontWeight: 600,
											textDecoration: 'none',
											color: Palette.THEME_WHITE
										}} to={obj.path}>{obj.name}</Link>
									</Col>
								)
							})
						}

					</Row>
				</Col>
			</Row>
			<Row>
				<Col className={"d-flex flex-column " + isMobile ? "mb-4" : ""} md={5}>
					<div style={{
						fontWeight: 500,
						color: Palette.THEME_WHITE
					}} className={"d-flex flex-row mb-4"}>
						<br></br>Copyright {new Date().getFullYear()}. All Rights Reserved
					</div>
				</Col>
				{/* <Col style={{ marginLeft: 20 }} className={"d-flex flex-column" + isMobile ? "mb-4" : ""} md={3}>
                    <Link style={{
                        fontWeight: 600,
                        color: Palette.THEME_WHITE,
                        textDecoration: 'none',
                    }} className={"d-flex flex-row mb-4"}>
                        Privacy Policy
                    </Link>
                </Col> */}
			</Row>
		</Container>
	</div>
}