import andika_acv from '../../assets/achievement/andhika_unibraw.jpeg'
import salsabila_acv from '../../assets/achievement/salsabila_udayana.jpeg'
import gustiara_acv from '../../assets/achievement/gustiara_upn.jpeg'
import ibrahim_acv from '../../assets/achievement/ibrahim_haluoleo.jpeg'
import marchela_acv from '../../assets/achievement/marchela_unj.jpeg'
import maulana_acv from '../../assets/achievement/maulana_yusuf_unila.jpeg'
import devira_acv from '../../assets/achievement/devira_udayana.jpeg'
import nail_acv from '../../assets/achievement/nail_unj.jpeg'
import rakha_acv from '../../assets/achievement/rakha_catra_unj.jpeg'
import raffa_acv from '../../assets/achievement/raffa_unj.jpeg'
import siti_zahra_acv from '../../assets/achievement/siti_zahira_unnes.jpeg'
import syahrini_acv from '../../assets/achievement/syahrini_undip.jpeg'
import genggam_acv from '../../assets/achievement/genggam_unibraw.jpeg'
import osisImage1 from '../../assets/osis/osisImage1.png'
import osisImage2 from '../../assets/osis/osisImage2.png'
import osisImage3 from '../../assets/osis/osisImage3.png'

import irAminah from '../../assets/teacher/Ir. Aminah.jpg'
import maretha from '../../assets/teacher/Maretha Murti Kundari, S.H.jpg'
import suswati from '../../assets/teacher/Suswati, M.Pd.jpg'
import lin from '../../assets/teacher/Iin Chintya Asmara, M.Pd.jpg'
import yudi from '../../assets/teacher/Yudi Rianto, S.Pd.jpg'
import andi from '../../assets/teacher/Andi Yuniawan, B.A.jpg'
import arofik from '../../assets/teacher/Arrofik Showi, S.Ag.jpg'
import sutrimo from '../../assets/teacher/Sutrimo, S.Pd.jpg'
import muarip from '../../assets/teacher/Muarip, S.Pd.jpg'
import bimansyah from '../../assets/teacher/Bimasyah Sihite, S.Pd.jpg'
import dianita from '../../assets/teacher/Dianita Wulansari, S.Pd.jpg'
import sri_aminah from '../../assets/teacher/Dra. Sri Aminah.jpg'
import doddy from '../../assets/teacher/Dody Riswanto, M.Pd.jpg'
import maharni from '../../assets/teacher/Muharni Afridita, S.Pd.jpg'
import dita from '../../assets/teacher/Dita Mulyawati, S.Pd.jpg'
import hairunnisyah from '../../assets/teacher/Hairunnisyah, S.Pd.jpg'
import yoto from '../../assets/teacher/Yoto, S.E.jpg'
import rita from '../../assets/teacher/Rita Kusuma Dewi, S.Pd.jpg'
import chairil from '../../assets/teacher/ChairiSH.jpg'
import ishadi from '../../assets/teacher/Ishadi, S.E.jpg'
import eko from '../../assets/teacher/Eko Prasetyo.jpg'
import kusyadi from '../../assets/teacher/Kusyadi.jpg'
import noImage from '../../assets/noimage.png'

export default {
	MENU_HEADER: [
		{ name: "Home", path: "/" },
		{
			name: "About School", path: "/about", submenu: [
				{
					name: "History", path: '/history'
				},
				{
					name: "General Information", path: '/about'
				},
				{
					name: "Vision and Mission", path: '/about?ref=vision'
				},
				{
					name: "Core Value", path: '/about?ref=core-value'
				},
				// {
				//     name: "Ekstrakulikuler", path: '/ekstrakulikuler'
				// },
				{
					name: "School Gallery", path: '/gallery'
				},

			]
		},
		{
			name: "Teachers & Staff", path: "/teacher",
			// submenu: [
			// 	{
			// 		name: "Guru", path: '/teacher'
			// 	},
			// 	{
			// 		name: "Anggota Yayasan", path: '/foundation'
			// 	},

			// ]
		},
		{
			name: "Studentships", path: "#", submenu: [
				{
					name: "Student Council", path: '/studentship/osis'
				},
				{
					name: "Student Achievement", path: '/studentship/achievement'
				},

				{
					name: "School Activity", path: '/studentship/activity'
				},


			]
		},
		{ name: "Acceptance of New Students", path: "/admission" },
		{ name: "FAQ", path: "/faq" },
		// {name : "Our Services", path : "/services"},
	],

	MENU_FOOTER: [
		{ name: "Home", path: "/", group: 1 },
		{ name: "Teachers & Staff", path: "/teacher", group: 1 },
		{ name: "Acceptance of New Students", path: "/admission", group: 1 },
		{ name: "FAQ", path: "/faq", group: 1 },
		{ name: "Alumni", path: "https://www.facebook.com/groups/65729086314/", group: 1, rawLink: true },
		{
			name: "About", path: "/about", isTitle: true, group: 2
		},
		{
			name: "History", path: '/history', group: 2
		},
		{
			name: "General Information", path: '/about', group: 2
		},
		{
			name: "Vission and Mission", path: '/about?ref=vision', group: 2
		},
		{
			name: "Core Value", path: '/about?ref=core-value', group: 2
		},
		// {
		//     name: "Ekstrakulikuler", path: '/ekstrakulikuler'
		// },
		{
			name: "School Gallery", path: '/gallery', group: 2
		},

	],
	HistoryPage: {
		title: "History",
		paragraphs: [
			"Sunda Kelapa High School, which was founded on August 8 1963, is one of the educational institutions that is an integral part of the Sunda Kelapa Education Foundation. This foundation was founded and led with great dedication by Mrs. Hj. S. Moerdono, a leading educational figure of her time. The process of establishing this educational institution also received valuable contributions from Mrs. Pia Alisyahbana and Mrs. Popy Awaludin, who acted as dedicated coaches.",
			"Sunda Kelapa High School was inaugurated by Mrs. Fatmawati Soekarno, the first First Lady of the Republic of Indonesia. With extraordinary enthusiasm in improving the quality of national education, this school continues to strive to make a significant contribution. To achieve this goal, Sunda Kelapa High School has received strong support from a number of influential individuals in society.",
			"Not only does it depend on external support, Sunda Kelapa High School is also known for having teaching staff who have high dedication, extensive experience and deep expertise in the field of education. They have played an important role in shaping and developing their students into individuals of quality and potential.",
			"Since it was founded and became one of the first private schools and can now be said to be one of the oldest private schools, Sunda Kelapa High School has become one of the educational institutions that is committed to carrying out an important mission in advancing education in Indonesia. Through high dedication and commitment, this school continues to be one of the pillars of education that has an influence in forming a young generation that is qualified and ready to face the future."
		]
	},
	AboutPage: {
		title: "About school",
		accreditations : {
			num_title: (<strong>Accreditation: </strong>),
			num_value: 'Excellent (Score: 92)',
			established_title: (<strong>Year founded: </strong>),
			established_value: 'August 8, 1963',
			established_by_title: (<strong>Founder: </strong>),
			established_by_value: 'Sjamsidar Moerdono',
		},
		mission: {
			title: "Mission",
			paragraphs: [
				"Increase Faith And Piety To God Almighty",
				"Upholding the Value of Honesty and Always Being Humble",
				`Realizing Concern for the Environment`,
				`Forming Responsible, Persistent and Creative Students`,
				`Creating graduates who are intellectual and humane`
			]
		},
		vision: {
			title: "Vision",
			paragraphs: [
				{
					name: "Devout Religion, Polite Behavior and Excellence in Achievement",
					icon: "fa-solid:praying-hands"
				}
			]
		},
		coreValues: {
			title: "Sunda Kelapa High School Core Values",
			paragraphs: [
				{
					title: "Religious",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>
									Increase faith and devotion to God Almighty.{" "}
								</li>
								<li>Perform worship according to each other's beliefs.</li>
								<li>Commemorating religious holidays.</li>
							</ul>
						</>
					)
				},
				{
					title: "Confident",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>Achievement in academic and non-academic fields.</li>
								<li>
									Achieve achievements in competitions both at school and outside of school in the fields of science, creation, arts and sports.
								</li>
								<li>Dare to express an opinion. </li>
								<li>Be responsible according to your duties. </li>
							</ul>
						</>
					)
				},
				{
					title: "Humble",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>Uphold the values ​​of honesty. </li>
								<li>Work together and work together for good.</li>
								<li>Instill caring and empathy towards others. </li>
								<li>Not arrogant and respect other people's opinions.</li>
							</ul>
						</>
					)
				},
				{
					title: "Polite",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>Instill polite and courteous attitudes in relationships.</li>
								<li>Speak polite words to anyone. </li>
								<li>Always be disciplined in implementing rules/regulations. </li>
								{/* <li>
									Selalu disiplin dalam melaksanakan tata-tertib/peraturan.
								</li> */}
							</ul>
						</>
					)
				},
				{
					title: "Creative",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>
									Able to create new work in the field of science.
								</li>
								<li>Able to be creative in the fields of arts and sports.</li>
								{/* <li>Menanamkan sifat peduli dan empati terhadap sesama. </li>
								<li>
									Selalu disiplin dalam melaksanakan tata-tertib/peraturan.
								</li> */}
							</ul>
						</>
					)
				},
				{
					title: "Innovative",
					detail: (
						<>
							<ul style={{ listStyle: "auto" }}>
								<li>Able to create scientific work results.</li>
								<li>Able to develop new ideas</li>
								<li>Be persistent in solving problems. </li>
								{/* <li>Tangguh dalam menyelesaikan masalah.</li> */}
							</ul>
						</>
					)
				}
			]
		},
		extracurricular: {
			title: "Ekstrakulikuler",
			description:
				"Kegiatan Sekolah SMA Sunda Kelapa terdapat berbagai cabang ekskul yang ditawarkan yang meliputi:",
			paragraphs: [
				{
					name: "Basket"
				},
				{
					name: "Voli"
				},
				{
					name: "Badminton"
				},
				{
					name: "Tari"
				},
				{
					name: "Paskibra"
				},
				{
					name: "Pramuka"
				},
				{
					name: "Futsal"
				},
				{
					name: "Karate"
				}
			]
		}
	},
	GalleryPage: {
		title: "Scholl Gallery",
		page: "Pages",
		outOf: "of"
	},
	TeacherPage: {
		teacher: {
			title: "Teachers and Staff",
			details: [
				{
					name: 'Ir. Aminah',
					position: 'Principal',
					image: irAminah,
					highlighted: true,
					tier: 1,
				},
				{
					name: 'Maretha Murti Kundari, S.H.',
					position: 'Vice Principal / Geography Teacher',
					image: maretha,
					highlighted: true,
					tier: 2,
				},
				{
					name: 'Suswati, M.Pd',
					position: 'Curriculum Coordinator / Mathematic teacher',
					image: suswati,
					highlighted: true,
					tier: 2,
				},
				{
					name: 'Iin Chintya Asmara, M.Pd',
					position: 'Mathematic Teacher',
					image: lin,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Yudi Rianto, S.Pd',
					position: 'Sociology Teacher / Student Council Advisor',
					image: yudi,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Andi Yuniawan, B.A',
					position: 'English Teacher',
					image: andi,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Arrofik Showi, S.Ag',
					position: 'Islamic Education Teacher',
					image: arofik,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Sutrimo, S.Pd',
					position: 'Indonesian Language Teacher',
					image: sutrimo,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Muarip, S.Pd',
					position: 'History Teacher',
					image: muarip,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Bimasyah Sihite, S.Pd',
					position: 'History Teacher',
					image: bimansyah,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Dianita Wulansari, S.Pd',
					position: 'Counseling Guidance Teacher',
					image: dianita,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Dra. Sri Aminah',
					position: 'Economy Teacher',
					image: sri_aminah,
					highlighted: true,
					tier: 3,
				},
				{
					name: 'Dody Riswanto, M.Pd',
					position: 'Counseling Guidance Teacher',
					image: doddy,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Muharni Afridita, S.Pd',
					position: 'Physics Teacher',
					image: maharni,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Dita Mulyawati, S.Pd',
					position: 'Biology Teacher',
					image: dita,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Hairunnisyah, S.Pd',
					position: 'Chemistry Teacher',
					image: hairunnisyah,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Yoto, S.E',
					position: 'Entrepreneurship Teacher - Computer Science Teacher',
					image: yoto,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Rita Kusuma Dewi, S.Pd',
					position: 'French Teacher',
					image: rita,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Chairil, S.H',
					position: 'Civic Education Teacher',
					image: chairil,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Ishadi, S.E',
					position: 'Head of Administration',
					image: ishadi,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Eko Prasetyo',
					position: 'Administration - IT Operator',
					image: eko,
					highlighted: false,
					tier: 3,
				},
				{
					name: 'Kusyadi',
					position: 'Administration',
					image: kusyadi,
					highlighted: false,
					tier: 3,
				},
			]
		},
		management: {
			title: "Anggota Yayasan",
			details: [
				{
					position: "Ketua Yayasan"
				},
				{
					position: "Wakil Ketua"
				},
				{
					position: "Pengawas"
				},
				{
					position: "Pembina"
				}
			]
		}
	},
	NewAdmissionPage: {
		downloadBrocureTitle: 'Download Brosur',
		section1: {
			title: "Acceptance of New Students",
			description: (
				<>
					The selection system for accepting new students at Sunda Kelapa High School is carried out through a direct or offline registration process. Prospective students come to school to register as participants in the selection for new student admissions. The registration process is carried out by visiting the Sunda Kelapa High School location according to the schedule determined by the school.
				</>
			)
		},
		section2: {
			title: "Registration process",
			description1:
				"",
			description2:
				(
					<>
						New student registration at <strong>Sunda Kelapa High School</strong> can be done directly <strong>(offline)</strong> at the school					</>
				),
			description3:
				"The following are the requirements that must be met when registering :",
			description4:
				"Registration information can be contacted via: (021) 3902839 (Call Center)",
			description5: 'Registration timeline: January - July',
			requirements: [
				"Registration form",
				`Photocopy of Junior High School Diploma`,
				`Pass-Photo 3x4 = 4 Sheets`,
				`Pass-Photo 2x3 = 2 Sheets`,
				`Photocopy of Family Card`,
				`Photocopy of Junior High School Report Card`
			]
		},
		section4: {
			title: "Tahapan",
			description: "Tahapan PSB Jalur",
			admissionTypes: {
				achievement: {
					title: "PSB Jalur Prestasi",
					short: "Prestasi",
					timeline: [
						{
							title: "Tahap 1",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 2",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 3",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 4",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 5",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 6",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						}
					]
				},
				test: {
					title: "PSB Jalur Tes Ujian",
					short: "Tes Ujian",
					timeline: [
						{
							title: "Tahap 1",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 2",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 3",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 4",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 5",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						},
						{
							title: "Tahap 6",
							description:
								"Teks berisi penjelasan terkait apa saja yang dilakukan pada tahap ini",
							date: "5-21 Oktober 2023"
						}
					]
				}
			}
		}
	},
	faqPage: {
		title: 'FAQ about Sunda Kelapa High School',
		qna: [
			{
				question: `What is the process for registering new students at this high school?`,
				answer: "Directly (offline) by visiting Sunda Kelapa High School and the requirements for prospective students are following the academic requirements"
			},
			{
				question: "How much does it cost to register at this high school?",
				answer: "The tuition fee is IDR 600,000 and the starting fee is around IDR 6,000,000."
			},
			{
				question: "What is the academic schedule and holidays this year?",
				answer: "Follow the established academic calendar"
			},
			{
				question: `How can I participate in clubs and extracurricular activities?`,
				answer: "There is a recruitment process through talent scouting will tailored to student interests "
			},
			{
				question: "What facilities are available at this high school?",
				answer: (
					<ol>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Canteen</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Computer Lab</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Science Lab</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Library</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Counseling room</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Student Council Room</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Medical room</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Mosque</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Prayer room</span>
								</span>
							</span>
						</li>
						<li style={{ listStyleType: "decimal" }}>
							<span>
								<span>
									<span style={{ color: "#000000" }}>Air-conditioned classroom</span>
								</span>
							</span>
						</li>
					</ol>
				)
			},
			{
				question: "What curriculum is used?",
				answer: "The curriculum used is the Merdeka Curriculum"
			},
			{
				question: "How do I contact school staff?",
				answer: (
					<>
						<p>For the general public, you can call the number(021) 390 2839</p>
						<p>Parents can contact students via Whatsapp Group</p>
					</>
				)
			},
			{
				question: "What makes this school unique?",
				answer: (
					<>
						<ul>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									<strong>Strategic location and in the center of Jakarta</strong>
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									{" "}
									A brawl-free school area
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									Easy to access
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									There is a Read and Write Al-Qur'an (BTQ) program facilitates.
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									<strong>One of the oldest private schools in Jakarta which has been around for 59 years</strong>
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									The building of Sunda Kelapa High School has been designated as a cultural heritage site
								</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>
									This school is adjacent to the Sunda Kelapa Grand Mosque
								</span>
							</li>
						</ul>
					</>
				)
			},
			{
				question:
					"How can I get the latest information about school activities and events?",
				answer: (
					<>
						<p>Through the 3 platforms that have been provided, namely:</p>
						<ul>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>Instagram</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>Website</span>
							</li>
							<li style={{ listStyleType: "disc" }}>
								<span style={{ color: "#000000" }}>Call Center</span>
							</li>
						</ul>
					</>
				)
			}
		]
	},
	HomePage: {
		welcome: {
			title: (
				<>
					Welcome to
					<br />
					Sunda Kelapa High School
				</>
			),
			description:
				"Sunda Kelapa High School is one of the oldest private schools located in Central Jakarta. Founded by education figure Mrs. Hj. Sjamsidar Moerdono, Sunda Kelapa High School was founded in 1963 with the aim of forging students into superior and accomplished individuals who are able to compete in the global era.",
			button: "Acceptance of New Students"
		},
		greetings: {
			title: "Principal's greeting",
			position: "Headmaster of Sunda Kelapa High School",
			paragraphs_short : (
				<>
					<p>
						Praise and gratitude always go to God Almighty for His abundance of grace and gifts, so that we are allowed to carry out a mandate in leading Sunda Kelapa High School. Our future program is to make Sunda Kelapa High School one of the schools whose students are environmentally friendly and can win championships from the city level to the national level.
					</p>
				</>
			),
			paragraph: (
				<>
					<p>
						Praise and gratitude always go to God Almighty for His abundance of grace and gifts, so that we are allowed to carry out a mandate in leading Sunda Kelapa High School. Our future program is to make Sunda Kelapa High School one of the schools whose students are environmentally friendly and can win championships from the city level to the national level. Of course, it is not easy, but with a strong will and good cooperation from all school residents, we are sure it can be achieved. May Allah bless and make it easy to achieve. Sunda Kelapa High School's website has become one of the choices for the community in finding out the profile, information, and activities at Sunda Kelapa High School. For this reason, it is appropriate for educational institutions to have a website to communicate between teachers, students, and parents. In addition, the school website can also be an information center for visitors and those who want to continue their higher education.
					</p>
					<p>Headmaster</p>
					<p>Ir. Aminah</p>
				</>
			)
		},
		about: {
			title: "About School",
			description:
				"Sunda Kelapa High School, founded in 1963, is the oldest private school committed to improving the quality of Indonesian education. Carrying the vision of Excellence in Achievement and Polite in Behavior, this school instills the main values ​​of faith, self-confidence, humility, creativity and innovation in order to form a strong generation to face the future.",
			more: "view more",
			less : "view less"
		},
		facilities: {
			title: "Facility",
			list: [
				{
					name: "Canteen",
					icon: 'material-symbols:store',
				},
				{
					name: "Computer Lab",
					icon: 'ri:computer-fill',
				},
				{
					name: "Science Lab",
					icon: 'medical-icon:i-laboratory',
				},
				{
					name: "Library",
					icon: 'mdi:library',
				},
				{
					name: "Counseling room",
					icon: 'raphael:bubble',
				},
				{
					name: "Student Council Room",
					icon: 'mingcute:microphone-fill',
				},
				{
					name: "Medical room",
					icon: 'mdi:medical-bag',
				},
				// {
				// 	name: "Mosque",
				// 	icon: `fluent:building-mosque-24-filled`,
				// },
				{
					name: "Prayer room",
					icon: `fluent:building-mosque-24-filled`,
				},
				{
					name: "Air-conditioned classroom",
					icon: `mdi:air-conditioner`,
				}
			]
		},
		activities: {
			title: "School Activity",
			description:
				"Sunda Kelapa High School School Activities have various activities on offer which include :",
			list: [
				{
					name: "Basketball",
					description: "Basketball club to hone skills, teamwork and maintain student fitness."
				},
				{
					name: "Study tours",
					description: "Exploration activities and visits to various places to broaden students' horizons and experiences."
				},
				{
					name: "Basic Student Leadership Training",
					description: "Basic Student Leadership Training, a place to hone student leadership skills through outbound and teamwork."
				},
				{
					name: "Laboratory",
					description: "Sunda Kelapa High School provides 2 laboratories, namely a science laboratory and a computer laboratory which are used to support student learning activities. These two laboratories are equipped with adequate equipment and facilities to help students carry out practicums and improve skills in the fields of science and information technology."
				},
				{
					name: "Student Council:",
					description: "Student Council (OSIS) of Sunda Kelapa High School provides opportunities for students to hone their leadership skills and creativity. The Student Council management is elected through elections held once a year. This is one way to introduce democracy among students."
				},
				{
					name: "Flag Raising Troops",
					description: "Flag Raising Troops to foster nationalism and student discipline."
				},
				{
					name: "Congregational Prayer",
					description: "Congregational prayers, especially during the Zuhr Prayer, are held regularly at the Sunda Kelapa Grand Mosque which is located next to Sunda Kelapa High School. This activity is the school's effort to instill the values ​​of religiosity and discipline in students. The Sunda Kelapa Grand Mosque is a center of worship and spiritual development for Sunda Kelapa High School residents."
				},
				{
					name: "Recite al-Qur’an",
					description: "Tadarus al-Quran is a routine carried out by Sunda Kelapa High School students. This activity of reading and studying the Koran aims to increase understanding and appreciation of the contents of the Islamic holy book. Through reciting al-Quran, it is hoped that it can develop morals and noble character in the younger generation"
				},
				{
					name: "Volleyball",
					description: "Volleyball club that trains students to work together, be disciplined and uphold sportsmanship."
				},
				{
					name: "Badminton",
					description: " A vehicle for increasing students' speed of thinking and agility through badminton."
				},
				{
					name: "Dancing",
					description: "Extracurricular expressing the art of dance to train students' creativity and cultural appreciation."
				},
				{
					name: "Scout",
					description: " A place to hone students' life and leadership skills through scouting activities."
				},
				{
					name: "Futsal",
					description: "Football sport that trains students' cooperation, strategy and fitness."
				},
				{
					name: "Karate",
					description: "Martial arts to train students' discipline, focus and self-defense."
				}

			]
		},
		facts: {
			students: "Students",
			teachers: "Teachers",
			admitted: "Entered PTN"
		},
		testimonial: {
			title: "Testimonial",
			list: [
				{
					message: `My daughter has become more confident and active in organizations since entering Sunda Kelapa High School. Thanks to patient teachers and a comprehensive approach in developing students, my daughter, who was previously shy, now has the courage to appear in public and was elected as Student Council treasurer. We are proud of its development Thanks to the right environment and assistance from the school." -Mrs. Rahma (student parent)						`,
					position: "-Mrs. Rahma (student parent)"
				},
				{
					message: `As a parent, I feel it is very appropriate to choose Sunda Kelapa High School as my eldest son's school. Here he not only gets a strong academic education, but also character and leadership education through Scout extracurricular activities. My son is now more independent, disciplined and has skills "Good organization thanks to this school education. I'm sure he's ready to achieve his dreams." -Mr Budi (student's parent)`,
					position: "Budi (student's parent)"
				},
			]
		}
	},
	OsisPage: {
		title: 'Student Council',
		description: `Student Council: Student Council (OSIS) of Sunda Kelapa High School provides opportunities for students to hone their leadership skills and creativity. The Student Council management is elected through elections held once a year. This is one way to introduce democracy among students. Apart from that, the school also entrusts the Student Council to organize events and activities such as competitions, National Holiday celebrations, and also charity activities. Student Council is an organization that is truly able to build students' leadership, independence and socialization skills.`,
		socialMediaTitle: 'Social Media',
		periodTitle: 'Last 3 Period',
		list: [
			{
				periodImage: osisImage1,
				periodTitle: 'Student Council Period 2023-2024',
				periodDescription: 'Emiestungkoro Cadudasa',

			},
			{
				periodImage: osisImage2,
				periodTitle: 'Student Council Period 2022-2023',
				periodDescription: '[Nama Kabinet Pengurus Osis]',

			},
			{
				periodImage: osisImage3,
				periodTitle: 'Student Council Period 2021-2022',
				periodDescription: 'Emiestungkoro Cadudasa',

			}

		]

	},
	AchievementPage: {
		title: 'Student Achievement',
		description: 'Sunda Kelapa High School succeeded in placing 17 of its graduates in 11 State Universities (PTN) spread throughout Indonesia this year. This achievement shows the quality of education as well as the academic and non-academic achievements of Sunda Kelapa High School students.		',
		subDescription: 'Students who successfully entered PTN included:',
		list: [
			{
				id: 1,
				name: 'Andika Hartawan',
				ptn_name: 'Brawijaya University',
				image: andika_acv
			},
			{
				id: 2,
				name: 'Salsabila',
				ptn_name: 'UDAYANA',
				image: salsabila_acv
			},
			{
				id: 3,
				name: 'Gustiara Widia Utari',
				ptn_name: 'UPN Veteran Jakarta',
				image: gustiara_acv
			},
			{
				id: 4,
				name: 'Allysandra',
				ptn_name: 'Jakarta State University',
				image: null
			},
			{
				id: 5,
				name: 'M. Ibrahim',
				ptn_name: 'Haluoleo University',
				image: ibrahim_acv
			},
			{
				id: 6,
				name: 'Siti Zahra',
				ptn_name: 'Semarang State University',
				image: siti_zahra_acv,
			},
			{
				id: 7,
				name: 'M. Nail Khalifah',
				ptn_name: 'UJakarta State University',
				image: nail_acv
			},
			{
				id: 8,
				name: 'Anggi Fatma',
				ptn_name: 'UDAYANA',
				image: null
			},
			{
				id: 9,
				name: 'Indah Widanti',
				ptn_name: 'Makassar State University',
				image: null
			},
			{
				id: 10,
				name: 'Devira Julia Komara',
				ptn_name: 'UDAYANA',
				image: devira_acv
			},
			{
				id: 11,
				name: 'M. Raffa',
				ptn_name: 'Jakarta State University',
				image: raffa_acv
			},
			{
				id: 12,
				name: 'Genggam Yoga Hartomo',
				ptn_name: 'Brawijaya University',
				image: genggam_acv
			},
			{
				id: 13,
				name: 'Rakha Catra',
				ptn_name: 'Jakarta State University',
				image: rakha_acv
			},
			{
				id: 14,
				name: 'Syahrani',
				ptn_name: 'Diponegoro University',
				image: syahrini_acv
			},
			{
				id: 15,
				name: 'Dian Alivia',
				ptn_name: 'University of Indonesia'
			},
			{
				id: 16,
				name: 'M. Maulana Yusuf',
				ptn_name: 'University of Lampung',
				image: maulana_acv
			},
			{
				id: 17,
				name: 'Marchela Calista',
				ptn_name: 'Jakarta State University',
				image: marchela_acv

			},

		]
	},
	footerInfo: {
		address: 'Jl. Taman Sunda Kelapa No 16. A Menteng - Central Jakarta',
		footerTitle: 'Sunda Kelapa High School'
	},
	links: {
		home: "Home",
		teachers: "Teachers and Staf",
		newStudents: {
			long: "Acceptance of New Students",
			short: "New Students"
		},
		faq: "FAQ",
		about: "About school",
		history: "Sejarah",
		generalInfo: "Informasi Umum",
		visionmission: "Vision and Mission",
		coreValue: "Core Value",
		gallery: "Galeri Sekolah",
		shortLink: "Short Link"
	}
}
