import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Button } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/reusable/ScrollToTop";
import Router from "./components/Router";
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
function App() {

    return (
        <>
            <Helmet>
                <title>SMA Sunda Kelapa Menteng</title>
                <meta name="description" content="SMA Sunda Kelapa merupakan salah satu sekolah swasta tertua yang berlokasi di Jakarta Pusat. Didirikan oleh tokoh pendidikan Ny. Hj. Sjamsidar Moerdono, SMA Sunda Kelapa berdiri sejak tahun 1963 dengan tujuan untuk menempa peserta didik menjadi individu yang unggul dan berprestasi serta mampu bersaing di era global." />
            </Helmet>
            <BrowserRouter>
                <ScrollToTop />
                <Router />
            </BrowserRouter>
        </>

    );
}

export default App;
