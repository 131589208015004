import basketImage from "../../assets/activity/basket.jpg"
import paskibraImage from "../../assets/activity/paskib.jpg"
import tariImage from "../../assets/activity/tari.jpg"
import karateImage from "../../assets/activity/karate.jpg"

export default [
    {
        name: 'Basket',
        image: basketImage,
    },
    {
        name: 'Voli',
        image: null,
    },
    {
        name: 'Badminton',
        image: null,
    },
    {
        name: 'Tari',
        image: tariImage,
    },
    {
        name: 'Paskibra',
        image: paskibraImage,
    },
    {
        name: 'Pramuka',
        image: null,
    },
    {
        name: 'Futsal',
        image: null,
    },
    {
        name: 'Karate',
        image: karateImage,
    },
]