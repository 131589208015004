import { Col, Container, Row } from "react-bootstrap";
import CustomHeading from "../reusable/CustomHeading";
import { useOutletContext } from "react-router-dom";
export default function StudentshipPage({ props }) {
    const [isMobile] = useOutletContext()
    return (
        <>
            <Container style={{ padding: isMobile ? '1rem 1rem' : '8rem 0rem', height: '100vh' }}>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="jumbotron text-center">
                           <h1 style={{fontWeight:800, fontSize:50}}>THIS PAGE IS UNDER CONSTRUCTION</h1>
                        </div>
                    </div>
                </div>

            </Container>
        </>
    )

}