
import karyawisataImage from "../../assets/activity/karyawisata-enhance.jpg"
import basketImage from "../../assets/activity/basket.jpg"
import kegiatanImage from "../../assets/activity/kegiatan.jpg"
import osisImage from "../../assets/activity/osis.jpg"
import paskibraImage from "../../assets/activity/paskib.jpg"
import labImage from "../../assets/activity/laboratorium.jpg"
import tariImage from "../../assets/activity/tari.jpg"
import YPIImage from "../../assets/activity/YPI.png"
import karateImage from "../../assets/activity/karate.jpg"
import aboutFull from '../../assets/about/banner_1_tall.jpg'
import coreValueImage from '../../assets/posterCoreValues.jpg'

import aboutImage1 from "../../assets/about/banner_1_tall.jpg"
import aboutImage2 from "../../assets/about/banner_2_small.jpg"
import aboutImage3 from "../../assets/about/banner_3_small.jpg"
import aboutImage4 from "../../assets/about/banner_4_tall.jpg"

export default [
    {
        name: 'gallery',
        image: aboutImage1
    },
    {
        name: 'gallery',
        image: aboutImage2
    },
    {
        name: 'gallery',
        image: aboutImage3
    },
    {
        name: 'gallery',
        image: aboutImage4
    },
    {
        name: 'gallery',
        image: karyawisataImage
    },
    {
        name: 'gallery',
        image: basketImage
    },
    {
        name: 'gallery',
        image: kegiatanImage
    },
    {
        name: 'gallery',
        image: osisImage
    },
    {
        name: 'gallery',
        image: paskibraImage
    },
    {
        name: 'gallery',
        image: labImage
    },
    {
        name: 'gallery',
        image: tariImage
    },
    {
        name: 'gallery',
        image: YPIImage
    },
    {
        name: 'gallery',
        image: karateImage
    },
    {
        name: 'gallery',
        image: aboutFull
    },
    {
        name: 'gallery',
        image: coreValueImage
    },

]