import { Icon } from "@iconify/react";

export default function CustomIcon({
    clientIcon,
    clientText,
    clientTextSize,
    clientHeight,
    clientWidth,
    rowClass
}) {
    return (
        <>
            <div className={`d-flex ${rowClass}`}>
                <div
                    style={{
                        width: clientWidth ?? '2.75rem',
                        height: clientHeight ?? '2.7rem',
                        padding: "0.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(13, 174, 86, 0.16)",
                        color: "#0DAE56",
                        borderRadius: "100%"
                    }}
                >
                    <Icon style={{ fontSize: 20 }} icon={clientIcon} />
                </div>
                <div style={{ marginLeft: 10, fontSize: clientTextSize, fontWeight: 600 }} className="my-auto">
                    {clientText}
                </div>
            </div>


        </>
    )
}