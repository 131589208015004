import { Accordion, Container, Row } from "react-bootstrap"
import { useOutletContext } from "react-router-dom"
import CustomHeading from "../reusable/CustomHeading"

export default function FaqPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    return (
        <>
            <Container style={{ padding: isMobile ? '1rem 1rem' : '8rem 0rem' }}>
                <Row className="mb-5">
                    <CustomHeading text={lang.faqPage.title} />
                </Row>
                <div className="mt-4 mb-5">
                    <Accordion defaultActiveKey={0}>
                        {
                            lang.faqPage.qna.map((value, key) => {
                                return (
                                    <>
                                        <Accordion.Item eventKey={key}>
                                            <Accordion.Header><b style={{ fontSize: isMobile ? '0.9em' : '1em' }}>{value.question}</b></Accordion.Header>
                                            <Accordion.Body style={{ fontSize: isMobile ? '0.8em' : '0.9em' }}>
                                                {value.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>

                                )
                            })
                        }
                    </Accordion>
                </div>
            </Container>
        </>
    )
}